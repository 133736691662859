import HierarchyState from "./state";
import { HierarchyTypes } from "./types";

export const initialState: HierarchyState = {
    hierarchies: [],
    hierarchiesDomain: [],
    companiesDomain: []
}

export function hierarchyReducer(
    state = initialState,
    action: HierarchyTypes
): HierarchyState {
    switch (action.type) {
        case "CHANGE_HIERARCHIES":
            return {
                ...state, hierarchies: action.hierarchies
            }
        case "CHANGE_HIERARCHIES_DOMAIN":
            return {
                ...state, hierarchiesDomain: action.hierarchiesDomain
            }
        case "CHANGE_COMPANIES_DOMAIN":
            return {
                ...state, companiesDomain: action.companiesDomain
            }
        default:
            return state;
    }
}