import React, { useState } from "react"
import ContainerWrapper from "../../components/container"

import {
    PlusOutlined,
    UnorderedListOutlined,
} from '@ant-design/icons';
import PageTitle from "../../common/title";
import ActivitiesList from "./activities-list";
import { Button, Col, Row } from "antd";
import AddActivity from "./add-activity";
import { GetActivities_activities as Activity } from "../../graphql/generated/GetActivities";
import { useSelector } from "react-redux";
import { FlowScaleStore } from "../../redux/combineReducers";

export const Activities = () => {
    const [showAddActivityModal, setShowAddActivityModal] = useState<boolean>(false);
    const [currentActivity, setCurrentActivity] = useState<Activity | null>(null);
    const triggerAddActivity = React.useRef<any>(null)
    const userData = useSelector((state: FlowScaleStore) => state.auth.user);

    const onChangeActivity = (data: Activity) => {
        setCurrentActivity(data);
        setShowAddActivityModal(true);
    }

    const addActivity = () => {
        setCurrentActivity(null);
        setShowAddActivityModal(true);
    }

    return (
        <ContainerWrapper>
            <Row justify="space-between" align="middle" style={{ marginBottom: 10 }}>
                <Col>
                    {/* Titulo */}
                    <PageTitle title="Minhas atividades" icon={<UnorderedListOutlined />} />
                </Col>
                <Col>
                    {/* Adicionar atividade */}
                    <Button type="primary" icon={<PlusOutlined />} onClick={addActivity}>Adicionar atividade</Button>
                </Col>
            </Row>
            {/* Lista de Atividades */}
            <ActivitiesList triggerAddActivity={triggerAddActivity} changeActivity={onChangeActivity} />
            {/* Modal - Adicionar atividade */}
            <AddActivity
                activity={currentActivity}
                showModal={showAddActivityModal}
                closeModal={() => setShowAddActivityModal(false)}
                onCreateActivity={() => triggerAddActivity.current({ userId: userData?.id })}
            />
        </ContainerWrapper>
    )
}