import { Alert, Button, Card, Empty, Form, Input, message, Modal, Space, Typography } from "antd"
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useRef } from "react";
import { NestedHierarchy } from "../../../../redux/hierarchy/state"
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { MUTATION_DELETE_HIERARCHY, MUTATION_UPDATE_HIERARCHY } from "../../../../graphql/mutations/mutations";
import { useMutation } from "@apollo/client";
import { deleteHierarchyInput, updateHierarchyInput } from "../../../../graphql/generated/globalTypes";

const { Text } = Typography

export interface HierarchyItemProps {
    hierarchy: NestedHierarchy | null,
    onChangeHierarchy: () => void
}

export const HierarchyItem = (props: HierarchyItemProps) => {
    const [form] = Form.useForm();
    const formRef = useRef<any>(null);

    const [updateHierarchy, { loading: mutationUpdateHierarchyLoading }] = useMutation(MUTATION_UPDATE_HIERARCHY);
    const [deleteHierarchy, { loading: mutationDeleteHierarchyLoading }] = useMutation(MUTATION_DELETE_HIERARCHY);

    useEffect(() => {
        if (props.hierarchy) {
            const { name, description, type } = props.hierarchy;
            form.setFieldsValue({
                name: name,
                description: description,
                type: type
            })
        } else {
            form.setFieldsValue({
                name: null,
                description: null,
                type: null
            })
        }
    }, [props.hierarchy, form]);

    const confirmDelete = () => {
        Modal.confirm({
            title: `Excluindo a hierarquia: ${props.hierarchy?.name}`,
            icon: <ExclamationCircleOutlined />,
            content: 'Tem certeza que deseja excluir essa hierarquia? Se houver usuários atrelados a ela, será necessário atribuir individualmente um novo setor a cada um deles.',
            okText: 'Excluir',
            cancelText: 'Cancelar',
            onOk: removeHierarchy,
        });
    }

    const removeHierarchy = () => {
        const formData: deleteHierarchyInput = {
            where: {
                id: (props.hierarchy?.id as string).toString()
            }
        }

        deleteHierarchy({
            variables: {
                input: formData
            }
        })
            .then(() => {
                message.success('Hierarquia removida com sucesso');
                props.onChangeHierarchy();
            }).catch(() => {
                message.error('Não foi possível remover a hierarquia')
            });
    }

    const handleOk = () => {
        if (formRef && formRef.current) {
            formRef.current.submit();
        }
    };

    const onFinish = (values: any) => {

        const formData: updateHierarchyInput = {
            data: {
                name: values.name,
                description: values.description,
                type: values.type
            },
            where: {
                id: (props.hierarchy?.id as string).toString()
            }
        }

        updateHierarchy({
            variables: {
                input: formData
            }
        })
            .then(() => {
                message.success('Hierarquia atualizada com sucesso');
                props.onChangeHierarchy();
            }).catch(() => {
                message.error('Não foi possível atualizar a hierarquia')
            });
    };

    return (
        <>
            {props.hierarchy
                ?
                <>
                    {/* Açoes */}
                    <Card
                        actions={[
                            <Space>
                                <Button loading={mutationDeleteHierarchyLoading} type="ghost" danger key="submit" onClick={confirmDelete}>
                                    Excluir
                                </Button>
                                <Button loading={mutationUpdateHierarchyLoading} type="primary" key="submit" onClick={handleOk}>
                                    Editar
                                </Button>
                            </Space>
                        ]}>
                        <Alert message="Edite abaixo as informações dessa hierarquia." type="info" style={{ marginBottom: 15, zIndex: 1 }} showIcon />
                        <Form
                            ref={formRef}
                            name="update-hierarchy"
                            layout={'vertical'}
                            form={form}
                            requiredMark={false}
                            onFinish={onFinish}
                        >
                            {/* Nome */}
                            <Text type="secondary" >
                                <strong>Nome atual:</strong> {props.hierarchy?.name}
                            </Text>
                            <Form.Item
                                style={{ marginTop: 5 }}
                                name="name"
                                rules={[
                                    { required: true, message: 'É obrigatório informar o nome' }
                                ]}
                            >
                                <Input
                                    value={props.hierarchy?.name || ''}
                                    onChange={(value) => console.log({ value })} />
                            </Form.Item>

                            {/* Descricao */}
                            <Text type="secondary">
                                <strong>Descrição atual:</strong> {props.hierarchy?.description}
                            </Text>
                            <Form.Item
                                style={{ marginTop: 5 }}
                                name="description"
                                rules={[
                                    { required: true, message: 'É obrigatório informar a descrição' }
                                ]}
                            >
                                <TextArea
                                    value={props.hierarchy?.description || ''}
                                    rows={4}
                                    autoSize
                                    onChange={(value) => console.log({ value })} />
                            </Form.Item>

                            {/* Tipo */}
                            <Text type="secondary">
                                <strong>Tipo atual:</strong> {props.hierarchy?.type}
                            </Text>
                            <Form.Item
                                style={{ marginTop: 5 }}
                                name="type"
                                rules={[
                                    { required: true, message: 'É obrigatório informar o tipo. Só é permitido letras minúsculas, sem espaço e caracteres especiais.', pattern: RegExp('^[a-z]+$', 'g') }
                                ]}
                            >
                                <Input
                                    value={props.hierarchy?.type || ''}
                                    onChange={(value) => console.log({ value })} />
                            </Form.Item>
                        </Form>
                    </Card>
                </>
                : <Empty style={{ padding: 50 }} image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Crie uma hierarquia nova a partir da raiz selecionando o botão acima ou selecione uma hierarquia existente ao lado para fazer a gestão dela.'} />}
        </>
    )
}

