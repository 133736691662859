import { Button, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';

import { RoleModel, RolePermissionModel, UserModel } from '../../../services/model/role';
import { Register } from '../../../components/register';
import { TeamTable } from './team-table';
import { CanManageUsersPermissionByRoleType, GetRolePermissionByRoleType } from '../../../services/role';

export interface TeamListProps {
    userData: UserModel | null
}

const { TabPane } = Tabs;
const TeamsList = (props: TeamListProps) => {
    const [showRegisterModal, setShowRegisterModal] = useState<boolean>(false);
    const [rolePermission, setRolePermission] = useState<RolePermissionModel | undefined>(undefined);

    useEffect(() => {
        if (props?.userData)
            setRolePermission(GetRolePermissionByRoleType((props?.userData?.role as RoleModel)?.type));
    }, [props.userData]);

    return (
        <>
            {showRegisterModal && rolePermission && CanManageUsersPermissionByRoleType(rolePermission.type) && <Register
                managerId={props.userData?.id as number}
                companyId={Number(props.userData?.company?.id)}
                visible={true}
                onClose={() => setShowRegisterModal(false)}
            />}
            {
                <Tabs defaultActiveKey="1">
                    {props.userData?.manager && <TabPane tab="Equipe" key="1">
                        <TeamTable
                            managerId={(props.userData.manager as UserModel).id}
                            subtitle="Membros da equipe que você faz parte"
                            isManager={false}
                        />
                    </TabPane>}
                    {props.userData?.id && rolePermission && CanManageUsersPermissionByRoleType(rolePermission.type) && <TabPane tab="Sua equipe" key="2">
                        <TeamTable
                            managerId={props.userData.id}
                            subtitle="Membros da equipe onde você é líder"
                            isManager={true}
                            action={<Button key="manager-add-user" onClick={() => setShowRegisterModal(true)}>Adicionar usuário</Button>}
                        />
                    </TabPane>}
                </Tabs>
            }
        </>
    )
}

export default TeamsList;