import React, { useEffect, useState } from 'react';
import { Button, Card, message, Slider, Tooltip } from 'antd';
import {
    QuestionCircleOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ACTIVE_CHARGES_BY_USER, GET_CHARGES_HISTORY_BY_USER } from '../../graphql/queries/queries';
import { MUTATION_CREATE_CHARGE_BY_USER, MUTATION_UPDATE_CHARGE_BY_USER } from '../../graphql/mutations/mutations';
import { createChargeInput, updateChargeInput } from '../../graphql/generated/globalTypes';
import { CARD_TITLE } from '../../utils/styles';
import ChargeHistory from './history';
import { GetChargesByUser_charges as ChargeModel } from '../../graphql/generated/GetChargesByUser';
import { FlowScaleStore } from '../../redux/combineReducers';

export interface ChargeProps {
    userId?: number,
    readMode?: boolean
}

const Charge = (props: ChargeProps) => {
    const userData = useSelector((state: FlowScaleStore) => state.auth.user);

    const [chargeValue, setChargeValue] = useState<number>(0);
    const [userId, setUserId] = useState<number | undefined>(userData?.id);
    const { loading, data } = useQuery(GET_ACTIVE_CHARGES_BY_USER, {
        variables: { userId: userId || 0 },
    });

    const [updateCharge, { loading: updateChargeMutationLoading }] = useMutation(MUTATION_UPDATE_CHARGE_BY_USER);
    const [createCharge, { loading: createChargeMutationLoading }] = useMutation(MUTATION_CREATE_CHARGE_BY_USER);

    useEffect(() => {
        if (data?.charges && data?.charges.length) {
            let charge: ChargeModel = data.charges.filter((x: ChargeModel) => x.active)[0] || null;
            setChargeValue(charge?.charge);
        } else {
            setChargeValue(0);
        }
    }, [data]);


    useEffect(() => {
        if (props.userId)
            setUserId(props.userId);
        else
            setUserId(userData?.id);
    }, [props.userId, userData]);

    const marks = {
        0: {
            style: {
                color: 'green',
                paddingLeft: 5
            },
            label: '0%',
        },
        100: {
            style: {
                color: '#eb2f96'
            },
            label: <strong>100%</strong>,
        },
        200: {
            style: {
                color: '#f50',
                paddingRight: 30
            },
            label: <strong>200%</strong>,
        },
    };

    const helpTooltip = <Tooltip
        placement={'left'}
        overlayStyle={{ width: '100%' }}
        title="Altere a carga de acordo com suas atividades. Quanto mais sobrecarregado, maior a carga.">
        <Button type="text" title={'Ajuda'} icon={<QuestionCircleOutlined />} size={'small'} />
    </Tooltip>;

    const formatter = (value: any) => {
        return `${value}%`;
    }

    const afertChangeSlider = async (value: number) => {
        if (!userData?.id) return;

        if (data?.charges && data?.charges.length) {
            for (var charge of data?.charges) {
                const updateFormData: updateChargeInput = {
                    where: {
                        id: charge.id,
                    },
                    data: {
                        active: false
                    }
                }

                await updateCharge({
                    variables: {
                        input: updateFormData
                    },
                    refetchQueries: [
                        {
                            query: GET_CHARGES_HISTORY_BY_USER,
                            variables: {
                                userId: userId
                            }
                        }
                    ]
                });
            }
        }

        const createFormData: createChargeInput = {
            data: {
                user: userId?.toString(),
                charge: value
            }
        }

        createCharge({
            variables: {
                input: createFormData
            },
            refetchQueries: [
                {
                    query: GET_ACTIVE_CHARGES_BY_USER,
                    variables: {
                        userId: userId
                    }
                }
            ]
        })
            .then(() => {
                message.success('Carga atualizada com sucesso');
            })
            .catch(() => {
                message.error('Não foi possível atualizar a carga')
            });
    }

    return (
        <>
            <Card
                loading={loading || updateChargeMutationLoading || createChargeMutationLoading}
                title={'Carga'}
                headStyle={CARD_TITLE}
                extra={helpTooltip}
            >
                <Slider
                    disabled={props.readMode}
                    vertical={false}
                    marks={marks}
                    tooltipVisible
                    max={200}
                    tipFormatter={formatter}
                    onAfterChange={afertChangeSlider}
                    onChange={(value: number) => setChargeValue(value)}
                    value={chargeValue ? chargeValue : 0} />
            </Card>
            <ChargeHistory userId={userData?.id} />
        </>
    )
}

export default Charge;