import { NestedHierarchy } from "../../../../redux/hierarchy/state";
import { ReactElement } from "react";

export interface DataNode {
    title: string;
    key: number;
    icon: ReactElement,
    isLeaf?: boolean;
    children?: DataNode[];
    data: NestedHierarchy
}

export const initTreeData: DataNode[] = [];

export const parseHierarchyToTreeData = (hierarchies: Array<NestedHierarchy>): DataNode[] => {
    return hierarchies && hierarchies?.length
        ? hierarchies.map(h => {
            const isLeaf = h.hierarchies.length === 0;
            return {
                title: h.name,
                key: Number(h.id),
                isLeaf: isLeaf,
                children: !isLeaf ? parseHierarchyToTreeData(h.hierarchies) : [],
                data: h
            } as DataNode
        })
        : [];
}

export const findHierarchyByRelatedId = (treeData: DataNode[], relatedId: number): NestedHierarchy | undefined => {
    const data = treeData.find(t => t.key === relatedId)?.data;
    if (data) return data;
    if (typeof data === 'undefined') {
        for (var tree of treeData) {
            if (tree.children) {
                const childrenData = findHierarchyByRelatedId(tree.children, relatedId);
                if (childrenData)
                    return childrenData;
            }

        }
    }
}