import { setCollapseMenu, setMobileResolution, toggleAlert } from "./action";
import { AlertModel, CommonThunk } from "./model";

export const notificationMessage = (
    alert: AlertModel
): CommonThunk => async (dispatch, state, api) => {
    dispatch(toggleAlert(alert));
}

export const collapseMenu = (
    collapse: boolean
): CommonThunk => async (dispatch, state, api) => {
    dispatch(setCollapseMenu(collapse));
}

export const mobileResolution = (
    isMd: boolean
): CommonThunk => async (dispatch, state, api) => {
    dispatch(setMobileResolution(isMd));
}