import { Table, Space, Button, Popconfirm, message, Tooltip } from 'antd';
import React, { MutableRefObject, useCallback, useEffect, useState } from 'react';
import * as S from './styles';

import {
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import { GET_ACTIVITIES_BY_CREATED_AT_DATE, GET_ACTIVITIES_BY_USER_ID } from '../../../graphql/queries/queries';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import { GetActivities_activities as Activity } from '../../../graphql/generated/GetActivities';
import { MUTATION_DELETE_ACTIVITY } from '../../../graphql/mutations/mutations';
import { deleteActivityInput } from '../../../graphql/generated/globalTypes';
import moment from 'moment';
import ActivitiesChart from '../../../components/charts/activitiesChart';
import Charge from '../../../components/charge';
import ActivityHistories from './history';
import { FlowScaleStore } from '../../../redux/combineReducers';

export interface ActivitiesListProps {
    userId?: number,
    readMode?: boolean,
    changeActivity?: (activity: Activity) => void,
    triggerAddActivity?: MutableRefObject<any>
}

const ActivitiesList = (props: ActivitiesListProps) => {
    const userData = useSelector((state: FlowScaleStore) => state.auth.user);

    const [userId, setUserId] = useState<number | undefined>(userData?.id);
    const { loading, data } = useQuery(GET_ACTIVITIES_BY_USER_ID, {
        variables: { userId: userId || 0 },
    });
    const [deleteActivity, { loading: deleteLoading }] = useMutation(MUTATION_DELETE_ACTIVITY);
    const triggerActivitiesChartRefetch = React.useRef<any>(null)

    useEffect(() => {
        if (props.userId)
            setUserId(props.userId);
        else
            setUserId(userData?.id);
    }, [props.userId, userData]);

    const columns: any = [
        {
            title: 'Atividade',
            dataIndex: 'name',
            key: 'name',
            width: 200,
            ellipsis: true,
        },
        {
            title: 'Desafio',
            dataIndex: 'challenge',
            key: 'challenge',
            width: 150,
            ellipsis: true,
        },
        {
            title: 'Competência',
            dataIndex: 'competence',
            key: 'competence',
            width: 150,
            ellipsis: true,
        },
        {
            title: 'Criada em',
            dataIndex: 'created_at',
            key: 'created_at',
            responsive: ['md'],
            width: 200,
            ellipsis: true,
            render: (data: Activity, item: Activity) => (
                <span>{moment(item.created_at).format('DD/MM/YYYY - HH:mm:ss')}</span>
            )
        },
        {
            title: 'Atualizada em',
            dataIndex: 'updated_at',
            key: 'updated_at',
            responsive: ['md'],
            render: (data: Activity, item: Activity) => (
                <span>{moment(item.updated_at).format('DD/MM/YYYY - HH:mm:ss')}</span>
            )
        },
        {
            title: '',
            key: 'operation',
            fixed: 'right',
            width: 120,
            render: (data: Activity, item: Activity) => (
                <Space size="middle">
                    <Button type="primary" disabled={props.readMode} onClick={() => editActivity(item)} icon={<EditOutlined />} />
                    <Popconfirm
                        title={`Tem certeza que deseja excluir a tarefa: ${item.name}?`}
                        onConfirm={() => confirmDelete(item)}
                        okText="Sim"
                        cancelText="Não"
                        placement="left"
                    >
                        <Button disabled={props.readMode} loading={deleteLoading} type="dashed" icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Space>
            )
        },
    ];

    const confirmDelete = (data: Activity) => {
        const deleteData: deleteActivityInput = {
            where: {
                id: data.id
            }
        }

        deleteActivity({
            variables: {
                input: deleteData
            },
            refetchQueries: [
                {
                    query: GET_ACTIVITIES_BY_USER_ID,
                    variables: {
                        userId: userId
                    }
                }
            ],
        })
            .then(() => {
                message.success('Tarefa removida com sucesso');
                triggerActivitiesChartRefetch.current({ userId: userId });
            }).catch(() => {
                message.error('Não foi possível remover a tarefa');
            })
    }

    const editActivity = (data: Activity) => {
        if (props.changeActivity)
            props.changeActivity(data);

        triggerActivitiesChartRefetch.current({ userId: userId });
    }

    const addActivity = useCallback(() => {
        triggerActivitiesChartRefetch.current({ userId: userId });
    }, [userId]);

    useEffect(() => {
        if (props.triggerAddActivity)
            props.triggerAddActivity.current = addActivity
    }, [props.triggerAddActivity, addActivity]);

    return (
        <>
            {/* Grafico - Atividades */}
            <ActivitiesChart
                randomKey={userId}
                userId={userId}
                query={GET_ACTIVITIES_BY_CREATED_AT_DATE}
                variables={{ userId: userId || 0 }}
                triggerRefetch={triggerActivitiesChartRefetch}
            />
            {/* Grafico - Carga */}
            <Charge userId={userId} readMode={props.readMode} />
            {/* Tabela */}
            <Table
                loading={loading}
                columns={columns}
                title={() => 'Atividades'}
                dataSource={data ? data.activities : []}
                rowKey={(record: Activity) => record.id}
                expandable={{
                    expandIcon: ({ expandable, expanded, onExpand, record }) =>
                        expanded ? (
                            <Tooltip title={expandable ? "Ocultar histórico" : null}>
                                <S.ActivityHistoryIcon
                                    isEnabled={expandable}
                                    active={true}
                                    onClick={e => expandable ? onExpand(record, e) : null}
                                />
                            </Tooltip>
                        ) : (
                            <Tooltip title={expandable ? "Visualizar histórico" : "Sem histórico"}>
                                <S.ActivityHistoryIcon
                                    isEnabled={expandable}
                                    active={false}
                                    onClick={e => expandable ? onExpand(record, e) : null}
                                />
                            </Tooltip>
                        ),
                    expandedRowRender: (record: Activity) => <ActivityHistories activityHistories={record.activity_histories} />,
                    rowExpandable: (record: Activity) => record.activity_histories.length > 0,
                }}
                locale={{
                    emptyText: 'Nenhuma atividade encontrada.'
                }}
                scroll={{ x: 300 }}
                sticky />
        </>
    )
}

export default ActivitiesList;