import { Drawer, List } from 'antd'
import styled from 'styled-components'

export const DrawerNotification = styled(Drawer)`
    .ant-drawer-header {
        padding: 10px !important;
    }

    .ant-drawer-close {
        padding: 15px 10px !important;
    }

    .ant-drawer-body {
        padding: 0 !important;
    }
`

export const ListItemNotification = styled(List.Item) <{ active: boolean, currentTheme: string | undefined }>`
    padding: 10px !important;
    color: ${props => props.active === true ? 'rgb(24, 144, 255)' : '#777777'} !important;
    background: ${props => props.active === true ? 'rgb(230, 247, 255)' : props.currentTheme === 'light' ? '#FFFFFF' : '#222222'} !important;
    
    .notification-create_at {
        font-size: 12px;
        color: ${props => props.active === true ? 'rgb(24, 144, 255)' : '#666666'} !important;
        display: block;
    }
`