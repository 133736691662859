import {
    TeamOutlined,
    ToolOutlined,
    UnorderedListOutlined,
} from '@ant-design/icons';
import { RoleTypeModel } from '../../services/model/role';

export interface MenuItemModel {
    name: string,
    route: string,
    icon: JSX.Element,
    visibleForRoles: Array<RoleTypeModel>
}

const menuItems: Array<MenuItemModel> = [
    {
        name: 'Atividades',
        icon: <UnorderedListOutlined />,
        route: '/',
        visibleForRoles: [RoleTypeModel.analista, RoleTypeModel.lider, RoleTypeModel.administrador, RoleTypeModel.onboarding]
    },
    {
        name: 'Equipe',
        icon: <TeamOutlined />,
        route: '/teams',
        visibleForRoles: [RoleTypeModel.analista, RoleTypeModel.lider, RoleTypeModel.administrador]
    },
    {
        name: 'Configurações',
        icon: <ToolOutlined />,
        route: '/configuration',
        visibleForRoles: [RoleTypeModel.administrador, RoleTypeModel.onboarding]
    },
]

export default menuItems;