import { ApolloProvider } from '@apollo/client';
import Chart from 'chart.js';
import React, { useEffect } from 'react';
import { Routes } from './routes';
import { useApollo } from './utils/apollo';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as Annotation from 'chartjs-plugin-annotation';
import { API_URL, NODE_ENV } from './config/config';

function App() {
  const client = useApollo(null)
  console.log({ url: NODE_ENV })
  console.log({ url: API_URL })

  useEffect(() => {
    Chart.pluginService.register(ChartDataLabels);
    Chart.pluginService.register(Annotation);
  }, []);

  return (
    <div className="main fade-in">
      <ApolloProvider client={client}>
        <Routes />
      </ApolloProvider>
    </div>
  );
}

export default App;
