import { Row, Col, Button, PageHeader, Tag } from "antd";
import { Header } from "antd/lib/layout/layout";
import {
    PoweroffOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { RoleModel, RolePermissionModel } from "../../../services/model/role";
import { doLogout } from "../../../redux/auth/thunk";
import * as S from './styles'
import {
    DotChartOutlined
} from '@ant-design/icons';
import NotificationBadge from "./notification";
import ProfileBadge from "./profile";
import { FlowScaleStore } from "../../../redux/combineReducers";
import { useEffect, useState } from "react";
import { GetRolePermissionByRoleType } from "../../../services/role";

const ContainerHeader = () => {
    const dispatch = useDispatch();
    const userData = useSelector((state: FlowScaleStore) => state.auth.user);
    const [rolePermission, setRolePermission] = useState<RolePermissionModel | undefined>(undefined);

    useEffect(() => {
        if (userData)
            setRolePermission(GetRolePermissionByRoleType((userData?.role as RoleModel)?.type));
    }, [userData]);

    const logout = () => {
        dispatch(doLogout())
    }

    return (
        <Header style={{ padding: 0 }}>
            <Row justify="space-between" style={{ alignItems: "center" }}>
                <Col>
                    <S.Logo>
                        <span>
                            <DotChartOutlined style={{ marginRight: 5 }} />Flow Scale
                        </span>
                        <span>
                            {userData && userData?.company && <small style={{ color: 'rgba(255, 255, 255, 0.5)' }}>{userData?.company?.name}</small>}
                        </span>
                    </S.Logo>
                </Col>
                <Col>
                    <PageHeader
                        style={{ padding: '0 24px 10px 24px' }}
                        subTitle={<span style={{ color: 'rgba(255,255,255,0.5)' }}>{userData?.username}</span>}
                        tags={rolePermission ? <Tag color={rolePermission.badgeColor}>{rolePermission.name}</Tag> : []}
                        extra={[<ProfileBadge key={0} />, <NotificationBadge key={1} />, <Button
                            key={2}
                            type="default"
                            title="Logout"
                            onClick={logout}
                            style={{ color: 'white', background: 'transparent', border: 'none' }}
                            icon={<PoweroffOutlined />}
                        />]}
                    ></PageHeader>
                </Col>
            </Row>
        </Header>
    )
}

export default ContainerHeader;