import { Card } from 'antd';
import { LoginForm } from './form';
import * as S from './styles'

export const Login = () => {
    return (
        <S.LoginWrapper>
            <Card>
                <LoginForm />
            </Card>
        </S.LoginWrapper>
    )
}