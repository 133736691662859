import { Alert, Empty, Tree } from "antd";
import { MutableRefObject, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FlowScaleStore } from "../../../../redux/combineReducers";
import { HierarchyThunk } from "../../../../redux/hierarchy/model";
import { NestedHierarchy } from "../../../../redux/hierarchy/state";
import { getRootHierarchiesByName } from "../../../../redux/hierarchy/thunk";
import { initTreeData, parseHierarchyToTreeData } from "./service";

interface CompanyListProps {
    currentCompany?: NestedHierarchy | null,
    onSelectCompany: (hierarchy: NestedHierarchy) => void,
    triggerChangeCompany: MutableRefObject<(companyId: number) => void>
}

export const CompanyList = (props: CompanyListProps) => {
    const dispatch = useDispatch();
    const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
    const [treeData, setTreeData] = useState(initTreeData);
    const companiesDomain = useSelector((state: FlowScaleStore) => state.hierarchy.companiesDomain);
    const dispatchCallback = useCallback((func: HierarchyThunk) => dispatch(func), [dispatch]);
    const refetchCompanies = useCallback(() =>
        dispatchCallback(getRootHierarchiesByName()),
        [dispatchCallback]);
        
    useEffect(() => {
        refetchCompanies();
    }, [refetchCompanies]);

    /* Ao carregar hierarquias */
    useEffect(() => {
        setTreeData(parseHierarchyToTreeData(companiesDomain));
    }, [companiesDomain]);

    /* Limpa a hierarquia selecionada */
    useEffect(() => {
        if (!props.currentCompany) setSelectedKeys([]);
    }, [props.currentCompany])

    /* Atualiza a lista quando houver alguma modificação externa */
    useEffect(() => {
        props.triggerChangeCompany.current = refetchCompanies
    }, [props.triggerChangeCompany, refetchCompanies]);

    const onSelect = (selectedKeysValue: React.Key[], info: any) => {
        setSelectedKeys(selectedKeysValue);
        const company = treeData.find(t => t.key === Number(selectedKeysValue[0]))?.data;

        if (company)
            props.onSelectCompany(company);
    };

    return (
        <>
            {treeData && treeData.length
                ? <>
                    <Alert message="Clique no nome da hierarquia desejada para edita-lá." type="info" style={{ marginBottom: 15, zIndex: 1 }} showIcon />
                    <Tree
                        showLine={{ showLeafIcon: false }}
                        showIcon
                        defaultExpandAll
                        onSelect={onSelect}
                        selectedKeys={selectedKeys}
                        treeData={treeData} />
                </>
                : <Empty style={{ padding: 50 }} image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Nenhuma empresa cadastrada. Adicione uma nova empresa clicando no botão acima.'} />}
        </>
    )
}