import { Alert } from "antd"
import React, { useEffect } from "react"
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FlowScaleStore } from "../../redux/combineReducers";
import { CommonThunk } from "../../redux/common/model";
import { notificationMessage } from "../../redux/common/thunk";

const AlertNotification = () => {
    const dispatch = useDispatch();
    const dispatchCallback = useCallback((func: CommonThunk) => dispatch(func), [dispatch]);
    const alertState = useSelector((state: FlowScaleStore) => state.common.alert);
    
    useEffect(() => {
        if (alertState) {
            if (alertState.timer) {
                setTimeout(() => {
                    dispatchCallback(notificationMessage({ showAlert: false }));
                }, alertState.timer);
            }
        }
    }, [alertState, dispatchCallback]);  

    const handleClose = () => {
        dispatch(notificationMessage({ showAlert: false }))
    }

    return (
        <>
            {alertState?.showAlert ? (
                <Alert
                    type={alertState.type ? alertState.type : 'success'}
                    message={alertState.message}
                    banner
                    closable={alertState.closable}
                    afterClose={handleClose}
                />
            ) : null}
        </>
    )
}

export default AlertNotification;