import React, { useEffect, useState } from "react"
import {
    NotificationOutlined,
} from '@ant-design/icons';
import { Badge, Button, Empty, List, Tooltip } from "antd";
import * as S from './styles'
import { useSelector } from "react-redux"
import { useMutation, useQuery } from "@apollo/client";
import { GET_NOTIFICATIONS_COUNT, GET_NOTIFICATIONS_COUNT_NOT_READED, GET_USER_NOTIFICATIONS } from "../../../../graphql/queries/queries";
import { GetUserNotifications_notifications as UserNotification } from "../../../../graphql/generated/GetUserNotifications";
import moment from "moment";
import { MUTATION_MARK_NOTIFICATION_AS_VISUALIZED } from "../../../../graphql/mutations/mutations";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { FlowScaleStore } from "../../../../redux/combineReducers";

const NotificationBadge = () => {
    const userData = useSelector((state: FlowScaleStore) => state.auth.user);

    const { currentTheme } = useThemeSwitcher();
    const [visible, setVisible] = useState(false);
    const [limit, setLimit] = useState(10);

    const { loading, data } = useQuery(GET_USER_NOTIFICATIONS, {
        variables: { userId: userData?.id || 0, limit: limit },
    });
    const countData = useQuery(GET_NOTIFICATIONS_COUNT, {
        variables: { userId: userData?.id || 0 },
    });
    const notReadCountData = useQuery(GET_NOTIFICATIONS_COUNT_NOT_READED, {
        variables: { userId: userData?.id || 0, visualized: false },
    });

    const [markAsVisualized, { loading: mutationLoading }] = useMutation(MUTATION_MARK_NOTIFICATION_AS_VISUALIZED);
    const [notReadedCount, setNotReadedCount] = useState(0);
    const [showFetchMore, setShowFetchMore] = useState(true);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);

        var notificationsNotReaded: UserNotification[] = [];
        if (notReadedCount !== 0) {
            notificationsNotReaded = data.notifications.filter((x: UserNotification) => !x.visualized);
        }

        for (var notification of notificationsNotReaded) {
            markAsVisualized({
                variables: {
                    input: {
                        where: {
                            id: notification.id
                        },
                        data: {
                            visualized: true
                        }
                    }
                },
                refetchQueries: [
                    {
                        query: GET_USER_NOTIFICATIONS,
                        variables: {
                            userId: userData?.id,
                            limit: limit
                        }
                    },
                    {
                        query: GET_NOTIFICATIONS_COUNT_NOT_READED,
                        variables: {
                            userId: userData?.id,
                            visualized: false
                        }
                    }
                ]
            })
                .then(() => {
                    // message.success('Atividade cadastrada com sucesso');
                })
                .catch(() => {
                    // message.error('Não foi possível cadastrar a atividade')
                });
        }
    };

    const onLoadMore = () => {
        let moreLimit = limit + 10;
        setLimit(moreLimit);
        console.log('onLoadMore')
    };

    useEffect(() => {
        if (data && data.notifications) {
            if (countData?.data) {
                if (data.notifications.length >= countData.data.notificationsCount) {
                    setShowFetchMore(false);
                } else {
                    setShowFetchMore(true);
                }
            }
        }
    }, [data, countData]);

    useEffect(() => {
        if (notReadCountData && notReadCountData.data) {
            setNotReadedCount(notReadCountData.data.notificationsCount);
        }
    }, [notReadCountData]);

    const loadMore =
        <div
            style={{
                textAlign: 'center',
                margin: '12px 0',
                height: 32,
                lineHeight: '32px',
            }}
        >
            {showFetchMore
                ? <Button loading={mutationLoading || loading} onClick={onLoadMore}>Carregar mais</Button>
                : <p>Fim dos resultados</p>}
        </div>

    return (
        <>
            <Tooltip title={`${notReadedCount} notificações não lidas`}>
                <Badge count={notReadedCount} dot>
                    <NotificationOutlined onClick={showDrawer} style={{ color: 'white', marginLeft: 5, cursor: 'pointer' }} />
                </Badge>
            </Tooltip>
            <S.DrawerNotification
                title="Notificações"
                placement="right"
                closable={true}
                onClose={onClose}
                visible={visible}
            >
                {data && data.notifications?.length ?
                    <List
                        loading={loading || mutationLoading}
                        dataSource={data.notifications}
                        loadMore={loadMore}
                        renderItem={(item: UserNotification) => (
                            <S.ListItemNotification active={!item.visualized} currentTheme={currentTheme}>
                                {item?.message}
                                <span className="notification-create_at">{moment(item.created_at).locale('pt-br').fromNow()}</span>
                            </S.ListItemNotification>
                        )}
                    />
                    : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Nenhuma notificação'} />}
            </S.DrawerNotification>
        </>
    )
}

export default NotificationBadge;