import styled from 'styled-components'
import {
    HistoryOutlined
} from '@ant-design/icons';

export const ActivityHistoryIcon = styled(HistoryOutlined) <{ active: boolean, isEnabled: boolean }>`
    border-radius: 50%;
    font-size: 12px;
    padding: 5px;

    border: 1px solid ${props => props.active ? 'rgba(54, 162, 235, 1)' : '#ccc'};
    color: ${props => props.active ? 'rgba(54, 162, 235, 1)' : '#222222'} !important;

    cursor: ${props => props.isEnabled ? 'pointer' : 'not-allowed'};
    opacity: ${props => props.isEnabled ? 1 : 0.5};
`;
