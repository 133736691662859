import { Col, Row } from "antd";
import { useState } from "react";
import PageTitle from "../../common/title";
import ContainerWrapper from "../../components/container";
import { UserOutlined } from '@ant-design/icons'
import ForgotPassword from "../login/forgot-password";
import { useSelector } from "react-redux";
import ProfileForm from "./form";
import { FlowScaleStore } from "../../redux/combineReducers";

const Profile = () => {
    const userData = useSelector((state: FlowScaleStore) => state.auth.user);

    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState<boolean>(false);

    return (
        <ContainerWrapper>
            <Row justify="space-between" align="middle" style={{ marginBottom: 10 }}>
                <Col>
                    {/* Titulo */}
                    <PageTitle title="Editar perfil" icon={<UserOutlined />} />
                </Col>
                <Col>
                    {/* <Button type="primary" icon={<PlusOutlined />} onClick={addActivity}>Adicionar atividade</Button> */}
                </Col>
            </Row>
            {/* Form */}
            <ProfileForm userData={userData} openResetPasswordModal={() => setShowForgotPasswordModal(true)}/>
            {/* Modal - Recuperação de senha */}
            <ForgotPassword
                defaultEmail={userData?.email}
                showModal={showForgotPasswordModal}
                closeModal={() => setShowForgotPasswordModal(false)} />
        </ContainerWrapper>
    )
}

export default Profile;