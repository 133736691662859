import { useMutation } from "@apollo/client";
import { Button, Form, Input, message, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { GetAllHierarchiesByRelatedId_hierarchies as Hierarchy } from "../../../../graphql/generated/GetAllHierarchiesByRelatedId";
import { createHierarchyInput } from "../../../../graphql/generated/globalTypes";
import { MUTATION_CREATE_HIERARCHY } from "../../../../graphql/mutations/mutations";
import { FlowScaleStore } from "../../../../redux/combineReducers";
import { NestedHierarchy } from "../../../../redux/hierarchy/state";

export interface AddHierarchyProps {
    currentParentHierarchy?: NestedHierarchy | null,
    showModal: boolean,
    closeModal: () => void,
    onChangeHierarchy: () => void
}

const AddHierarchy = (props: AddHierarchyProps) => {
    const formRef = useRef<any>(null);
    const userData = useSelector((state: FlowScaleStore) => state.auth.user);
    const [parentHierarchy, setParentHierarchy] = useState<Hierarchy | null>(null);

    useEffect(() => {
        if (!props?.currentParentHierarchy && userData) {
            setParentHierarchy(userData.company);
        }

        if (props.currentParentHierarchy)
            setParentHierarchy(props.currentParentHierarchy);
    }, [props.currentParentHierarchy, userData]);

    const [createHierarchy, { loading: mutationCreateHierarchyLoading }] = useMutation(MUTATION_CREATE_HIERARCHY);
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        finishCreateHierarchy(values);
    };

    const finishCreateHierarchy = (values: any) => {
        const formData: createHierarchyInput = {
            data: {
                name: values?.name,
                description: values?.description,
                type: values?.type,
                related: parentHierarchy?.id.toString()
            }
        }

        createHierarchy({
            variables: {
                input: formData
            },
        })
            .then(() => {
                message.success('Hierarquia cadastrada com sucesso');
                props.closeModal();
                props.onChangeHierarchy();
            })
            .catch(() => {
                message.error('Não foi possível cadastrar a hierarquia. Entre em contato com o suporte.')
            });
    }

    const handleOk = () => {
        if (formRef && formRef.current) {
            formRef.current.submit();
        }
    };

    const handleCancel = () => {
        props.closeModal();
    };

    const loading = mutationCreateHierarchyLoading;

    return (
        <Modal
            centered
            visible={props.showModal}
            title={`Criar hierarquia abaixo de ${parentHierarchy?.name}`}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancelar
                </Button>,
                <Button loading={loading} key="submit" type="primary" onClick={handleOk}>
                    Criar
                </Button>
            ]}
        >
            <Form
                ref={formRef}
                name="add-hierarchy"
                layout={'vertical'}
                form={form}
                requiredMark={false}
                onFinish={onFinish}
            >
                <Form.Item
                    label="Nome"
                    name="name"
                    rules={[
                        { required: true, message: 'É obrigatório informar o nome' }
                    ]}
                >
                    <Input onChange={(value) => console.log({ value })} />
                </Form.Item>

                <Form.Item
                    label="Descrição"
                    name="description"
                    rules={[
                        { required: true, message: 'É obrigatório informar a descrição' }
                    ]}
                >
                    <TextArea rows={4} autoSize onChange={(value) => console.log({ value })} />
                </Form.Item>

                <Form.Item
                    label="Tipo"
                    name="type"
                    rules={[
                        { required: true, message: 'É obrigatório informar o tipo. Só é permitido letras minúsculas, sem espaço e caracteres especiais.', pattern: RegExp('^[a-z]+$', 'g') }
                    ]}
                >
                    <Input onChange={(value) => console.log({ value })} />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default AddHierarchy;