import { useMutation } from '@apollo/client';
import { Steps, Button, message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { createUserInput } from '../../../graphql/generated/globalTypes';
import { MUTATION_CREATE_USER } from '../../../graphql/mutations/mutations';
import { GET_USER_CREATED_FOR_COMPANY, GET_USER_TEAM_BY_MANAGER } from '../../../graphql/queries/queries';
import { RegisterBasicDataForm, RegisterBasicFormData } from '../basicDataForm';
import { RegisterManagerDataForm, RegisterManagerFormData } from '../managerDataForm';
import { RegisterSecurityDataForm, RegisterSecurityFormData } from '../securityDataForm';
import * as S from './styles'

export interface StepsRegisterFormProps {
    managerId: number,
    companyId: number,
    closeModal: () => void
}

const { Step } = Steps;
export const StepsRegisterForm = (props: StepsRegisterFormProps) => {
    // Register
    const [basicFormRef, setBasicFormRef] = useState<any>(null);
    const [basicFormData, setBasicFormData] = useState<RegisterBasicFormData | null>(null);

    // Security
    const [securityFormRef, setSecurityFormRef] = useState<any>(null);
    const [securityFormData, setSecurityFormData] = useState<RegisterSecurityFormData | null>(null);

    // Manager
    const [managerFormRef, setManagerFormRef] = useState<any>(null);
    const [managerFormData, setManagerFormData] = useState<RegisterManagerFormData | null>(null);

    const { currentTheme } = useThemeSwitcher();
    const [current, setCurrent] = useState(0);
    const [createUser, { loading: createUserLoading }] = useMutation(MUTATION_CREATE_USER);

    const changeBasicFormData = (data: RegisterBasicFormData) => {
        setBasicFormData(data);
        setCurrent(current + 1);
    }

    const changeSecurityFormData = (data: RegisterSecurityFormData) => {
        setSecurityFormData(data);
        setCurrent(current + 1);
    }

    const changeManagerFormData = (data: RegisterManagerFormData) => {
        setManagerFormData(data);
    }

    const saveUser = useCallback(() => {
        if (!basicFormData?.email || !basicFormData.username) return;
        const formData: createUserInput = {
            data: {
                email: basicFormData?.email,
                username: basicFormData?.username,
                confirmed: true,
                password: securityFormData?.password,
                role: managerFormData?.roleType.toString(),
                manager: managerFormData?.fromOnboarding ? null : props.managerId.toString(),
                company: managerFormData?.fromOnboarding
                    ? managerFormData?.hierarchy.toString() : props.companyId.toString(),
                hierarchy: managerFormData?.hierarchy.toString(),
                created_by: props.managerId.toString()
            }
        }

        createUser({
            variables: {
                input: formData
            },
            refetchQueries: [
                {
                    query: GET_USER_TEAM_BY_MANAGER,
                    variables: {
                        manager: props.managerId
                    }
                },
                {
                    query: GET_USER_CREATED_FOR_COMPANY,
                    variables: {
                        manager: props.managerId
                    }
                }
            ]
        })
            .then(() => {
                message.success('Usuário cadastrado com sucesso!');
                props.closeModal();
            })
            .catch(() => {
                message.error('Não foi possível cadastrar o usuário')
            });
    }, [basicFormData?.email, basicFormData?.username, createUser, managerFormData?.fromOnboarding, managerFormData?.hierarchy, managerFormData?.roleType, props, securityFormData?.password]);

    const steps = [
        {
            title: 'Dados básicos',
            content: <RegisterBasicDataForm
                getFormRef={(form: any) => setBasicFormRef(form)}
                basicFormData={basicFormData}
                setBasicFormData={changeBasicFormData} />,
        },
        {
            title: 'Segurança',
            content: <RegisterSecurityDataForm
                getFormRef={(form: any) => setSecurityFormRef(form)}
                securityFormData={securityFormData}
                setSecurtiyFormData={changeSecurityFormData} />,
        },
        {
            title: 'Permissão',
            content: <RegisterManagerDataForm
                getFormRef={(form: any) => setManagerFormRef(form)}
                managerFormData={managerFormData}
                setManagerFormData={changeManagerFormData} />,
        },
    ];

    const next = () => {
        switch (current) {
            case 0:
                if (basicFormRef && basicFormRef.current) {
                    basicFormRef.current.submit();
                }
                return;
            case 1:
                if (securityFormRef && securityFormRef.current) {
                    securityFormRef.current.submit();
                }
                return;
            case 2:
                if (managerFormRef && managerFormRef.current) {
                    managerFormRef.current.submit();
                }
                return;
            default:
                break;
        }
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    useEffect(() => {
        if (managerFormData)
            saveUser();
    }, [managerFormData, saveUser]);

    return (
        <>
            <Steps responsive={true} current={current}>
                {steps.map((item, i) => (
                    <Step key={i} title={item.title} />
                ))}
            </Steps>
            <S.StepsContent currentTheme={currentTheme}>{steps[current].content}</S.StepsContent>
            <S.StepsAction>
                {current < steps.length - 1 && (
                    <Button type="primary" onClick={() => next()}>
                        Próximo
                    </Button>
                )}
                {current === steps.length - 1 && (
                    <Button loading={createUserLoading} type="primary" onClick={() => next()}>
                        Cadastrar
                    </Button>
                )}
                {current > 0 && (
                    <Button loading={createUserLoading} style={{ margin: '0 8px' }} onClick={() => prev()}>
                        Voltar
                    </Button>
                )}
            </S.StepsAction>
        </>
    );
};