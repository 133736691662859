import { gql } from "@apollo/client";

export const GET_ACTIVITIES_BY_USER_ID = gql`
  query GetActivities($userId: Int!) {
    activities(where: { user: $userId }, sort: "created_at:desc") {
            id
            created_at
            updated_at
            name
            challenge
            competence
            user {
                id
                username
                created_at
                provider
                confirmed
                blocked
                role {
                    id
                    name
                    description
                    type
                }
            }
            activity_histories {
                id
                started_at
                finished_at
                competence
                challenge
                lastname
            }
        }
    }
`;

// Retorna uma lista de membros da equipe (Membro/Analista)
// É feito para o perfil Manager e Membro
export const GET_USER_TEAM_BY_MANAGER = gql`
    query GetUserTeamByManager($manager: Int!) {
        users(where: { manager: $manager }) {
            id
            username
            email
            blocked
            created_at
            updated_at
            role {
                id
                type
                name
            }
            manager {
                id
                username
            }
            company {
                id
                name
            }
            hierarchy {
                id
                name
            }
        }
    }
`

// Retorna a lista de atividades com base na data e usuário
export const GET_ACTIVITIES_BY_CREATED_AT_DATE = gql`
    query GetActivitiesByCreatedAtDate($userId: Int!, $createdAt: String) {
        activities(
            where: { user: $userId, created_at_gt: $createdAt }
            sort: "created_at:desc"
        ) {
            id
            created_at
            name
            challenge
            competence
            activity_histories {
                id
                started_at
                finished_at
                competence
                challenge
                lastname
            }
        }
    }
`

// Retorna a lista de atividades de todos os membros com base na data e líder
export const GET_ACTIVITIES_FROM_MEMBERS_BY_CREATED_AT_DATE = gql`
    query GetActivitiesFromMembersByCreatedAtDate(
        $managerId: Int!
        $createdAt: String
    ) {
        activities(
            where: { user: { manager: $managerId }, created_at_gt: $createdAt }
            sort: "created_at"
        ) {
            id
            created_at
            name
            challenge
            competence
        }
    }
`

// Retorna a quantidade de atividades registradas daquele usuário
export const GET_ACTIVITIES_COUNT = gql`
    query GetActivitiesCount($userId: Int!) {
        activitiesCount(
            where: { user: $userId }
        ) 
    }
`

// Retorna os dados do usuário em tempo real
export const GET_USER_DATA = gql`
    query GetUserData($userId: ID!) {
        user(id: $userId) {
            id
            created_at
            updated_at
            username
            email
            confirmed
            blocked
        }
    }
`

// Retorna as notificações do usuário
export const GET_USER_NOTIFICATIONS = gql`
    query GetUserNotifications($userId: Int!, $limit: Int!) {
        notifications(limit: $limit, where: { user: $userId }, sort: "created_at:desc") {
            id
            message
            created_at
            visualized
        }
    }
`

// Retorna a quantidade total de notificações do usuário
export const GET_NOTIFICATIONS_COUNT = gql`
    query GetNotificationsCount($userId: Int!) {
        notificationsCount(
            where: { user: $userId }
        ) 
    }
`

// Retorna a quantidade total de notificações do usuário
export const GET_NOTIFICATIONS_COUNT_NOT_READED = gql`
    query GetNotificationsCountNotReaded($userId: Int!, $visualized: Boolean!) {
        notificationsCount(
            where: { user: $userId, visualized: $visualized }
        ) 
    }
`

// Retona o histórico de carga de acrodo com o usuário
export const GET_CHARGES_HISTORY_BY_USER = gql`
    query GetChargesHistoryByUser($userId: Int!) {
        charges(
            sort: "updated_at:desc",
            where: { user: $userId, active: false }
        ) {
            id
            created_at
            updated_at
            charge
            active
        }
    }
`

// Retona a carga de acrodo com o usuário
export const GET_ACTIVE_CHARGES_BY_USER = gql`
    query GetChargesByUser($userId: Int!) {
        charges(
            where: { user: $userId, active: true }
        ) {
            id
            created_at
            updated_at
            charge
            active
        }
    }
`

// Retona a carga de acrodo com o usuário
export const GET_ALL_CHARGES_BY_USER = gql`
    query GetAllChargesByUser($userId: Int!, $createdAt: String) {
        charges(
            where: { user: $userId, created_at_gt: $createdAt }
        ) {
            id
            created_at
            updated_at
            charge
            active
        }
    }
`

// Retorna a lista de roles
export const GET_ALL_ROLES = gql`
    query GetAllRoles {
        roles {
            id
            name
            description
            type
        }
    }
`

// Retona a lista de hierarquias dado um relatedId
export const GET_ALL_HIERARCHIES_BY_RELATED_ID = gql`
    query GetAllHierarchiesByRelatedId($relatedId: Int!) {
        hierarchies(where: { related: $relatedId }) {
            id
            created_at
            updated_at
            name
            description
            root
            type
            related {
                id
            }
            enabled
        }
    }  
`

// Retorna a lista de usuários administradores de empresas
export const GET_USER_CREATED_FOR_COMPANY = gql`
    query GetUserCreatedForCompany {
        users(where: { hierarchy: { root: true } }) {
            id
            username
            email
            blocked
            created_at
            updated_at
            role {
                id
                type
                name
            }
            company {
                id
                name
                root
            }
            hierarchy {
                id
                name
                root
            }
        }
    }
`

export const GET_MANAGER_BY_NAME_AND_COMPANY_ID = gql`
    query GetManagersByNameAndCompanyId($name: String, $companyId: Int!) {
        users(where: { company: { id: $companyId }, username_contains: $name, role: { type_ne: ["analista" , "onboarding"]} }) {
            id
            username
            email
            blocked
            created_at
            updated_at
            role {
                id
                type
                name
            }
            company {
                id
                name
                root
            }
            hierarchy {
                id
                name
                root
            }
        }
    }
`