import React, { useCallback, useEffect, useState } from 'react';
import { AutoComplete } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { FlowScaleStore } from '../../redux/combineReducers';
import { getFlattedHierarchiesByName } from '../../redux/hierarchy/thunk';
import { HierarchyThunk } from '../../redux/hierarchy/model';

export interface AutoCompleteHierarchyProps {
    companyId: number | null,
    onSelect: (option: { value: string, label: string }) => void
}

export const AutoCompleteHierarchy = (props: AutoCompleteHierarchyProps) => {
    const dispatch = useDispatch();
    const dispatchCallback = useCallback((func: HierarchyThunk) => dispatch(func), [dispatch]);
    const getFlattedHierarchiesByNameCallback = useCallback(() => getFlattedHierarchiesByName(props.companyId), [props.companyId]);
    const [value, setValue] = useState('');
    const [options, setOptions] = useState<{ value: string, label: string }[]>([]);
    const hierarchiesDomain = useSelector((state: FlowScaleStore) => state.hierarchy.hierarchiesDomain);

    useEffect(() => {
        dispatchCallback(getFlattedHierarchiesByNameCallback());
    }, [dispatchCallback, getFlattedHierarchiesByNameCallback]);

    useEffect(() => {
        if (!hierarchiesDomain.length)
            setOptions([]);
        else
            setOptions(hierarchiesDomain.map((h) => ({ label: h.name, value: h.id.toString() })));

    }, [hierarchiesDomain]);

    const onSearch = (searchText: string) => {
        if (props.companyId) {
            dispatch(getFlattedHierarchiesByName(props.companyId, searchText));
        }
    };

    const onSelect = (data: string, option: { value: string, label: string }) => {
        console.log('onSelect', data);
        console.log('onSelect', option);
        props.onSelect(option);
    };

    const onChange = (data: any, option: any) => {
        console.log({ option });
        setValue(option.label);
    };

    return (
        <>
            <AutoComplete
                value={value}
                options={options}
                onSelect={onSelect}
                onSearch={onSearch}
                onChange={onChange}
                placeholder="Pesquise pelo setor"
            />
        </>
    );
};

