import { Card } from 'antd'
import styled from 'styled-components'

export const CardWrapper = styled(Card)`
    .ant-card-body {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .ant-card-loading-content {
        flex-basis: 100%;
        flex-grow: 0;
        flex-shrink: 0;
    }
`

export const ChartWrapper = styled.div`
    position: relative;
    margin: 15px 0;
`

export const LoadingContainer = styled.div`
    position:absolute;
    background-color: rgba(255,255,255,0.3);
    margin: 0;
    width: 100%;
    height: 100%;
    z-index: 999;

    .ant-spin {
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`