import { Select } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FlowScaleStore } from "../../redux/combineReducers";
import { RoleModel, RolePermissionModel } from "../../services/model/role";
import { GetRoleIdByType, GetRolesDomainCanBePromotedListByRoleType, GetRolesDomainListByRoleType } from "../../services/role";

export interface SelectPermissionsProps {
    currentPermission?: number | null,
    checkPromoted?: boolean,
    onSelect: (e: any) => void
}

export const SelectPermissions = (props: SelectPermissionsProps) => {
    const [permissions, setPermissions] = useState<RolePermissionModel[]>([]);
    const userData = useSelector((state: FlowScaleStore) => state.auth.user);
    const roles = useSelector((state: FlowScaleStore) => state.auth.roles);

    useEffect(() => {
        const role = roles.find(r => r.id === Number(props.currentPermission));
        const permissions = props.checkPromoted
            ? role?.type ? GetRolesDomainCanBePromotedListByRoleType(role?.type) : []
            : GetRolesDomainListByRoleType((userData?.role as RoleModel)?.type);

        if (permissions)
            setPermissions(permissions);

    }, [props.checkPromoted, props.currentPermission, roles, userData]);

    return (
        <Select
            style={{ minWidth: '100%' }}
            value={props.currentPermission}
            onChange={(e) => props.onSelect(e)}>
            {permissions.length ? permissions.map((p, i) => (
                <Select.Option key={i} value={GetRoleIdByType(roles, p.type)}>{p.name}</Select.Option>
            )) : null}
        </Select>
    )
}

