import React from 'react';
import { Collapse, Table } from 'antd';
import { useQuery } from '@apollo/client';
import { GET_CHARGES_HISTORY_BY_USER } from '../../../graphql/queries/queries';
import { GetChargesByUser_charges as ChargeModel } from '../../../graphql/generated/GetChargesByUser';
import moment from 'moment';
const { Panel } = Collapse;

export interface ChargeHistoryProps {
    userId: number | undefined
}

const ChargeHistory = (props: ChargeHistoryProps) => {
    const { loading, data } = useQuery(GET_CHARGES_HISTORY_BY_USER, {
        variables: { userId: props.userId || 0 },
    });

    const columns: any = [
        {
            title: 'Carga',
            dataIndex: 'charge',
            key: 'charge',
        },
        {
            title: 'Adicionado em',
            dataIndex: 'created_at',
            key: 'created_at',
            fixed: 'right',
            width: 200,
            responsive: ['md'],
            render: (data: ChargeModel[], item: ChargeModel) => (
                <span>{moment(item.created_at).format('DD/MM/YYYY - HH:mm:ss')}</span>
            )
        },
        {
            title: 'Atualizado em',
            dataIndex: 'updated_at',
            key: 'updated_at',
            fixed: 'right',
            width: 200,
            responsive: ['md'],
            render: (data: ChargeModel[], item: ChargeModel) => (
                <span>{moment(item.updated_at).format('DD/MM/YYYY - HH:mm:ss')}</span>
            )
        }
    ];

    return (
        <Collapse
            style={{ marginBottom: 15 }}
            bordered={false}
            onChange={() => null}
        >
            <Panel header="Histórico" key="1">
                <Table
                    loading={loading}
                    columns={columns}
                    size={'small'}
                    bordered
                    dataSource={data ? data.charges : []}
                    style={{ marginBottom: 0 }}
                    locale={{
                        emptyText: 'Nenhum histórico de carga encontrado.'
                    }}
                    scroll={{ x: 300 }}
                    sticky />
            </Panel>
        </Collapse>
    )
}

export default ChargeHistory;