import { AlertModel } from "./model";
import { CommonTypes } from "./types";

export function toggleAlert(alert: AlertModel): CommonTypes {
    return {
        type: "TOGGLE_ALERT",
        alert: alert
    }
}

export function setLoading(loading: boolean): CommonTypes {
    return {
        type: "SET_LOADING",
        loading: loading
    }
}

export function setCollapseMenu(collapsed: boolean): CommonTypes {
    return {
        type: "SET_COLLAPSE",
        collapsed: collapsed
    }
}

export function setMobileResolution(isMd: boolean): CommonTypes {
    return {
        type: "SET_MOBILE_RESOLUTION",
        isMd: isMd
    }
}