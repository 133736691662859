import { Button, Form, Input } from "antd";
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../redux/auth/thunk";
import ForgotPassword from "../forgot-password";
import { FlowScaleStore } from "../../../redux/combineReducers";
import PageTitle from "../../../common/title";
import { Typography } from 'antd'

const { Link } = Typography
interface LoginFormModel {
    email: string,
    password: string
}

export const LoginForm = () => {
    const dispatch = useDispatch();
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState<boolean>(false);
    const loading = useSelector((state: FlowScaleStore) => state.common.loading);

    const onFinish = (values: LoginFormModel) => {
        dispatch(login({ identifier: values.email, password: values.password }));
    };

    return (
        <>
            <PageTitle title="Login" icon={<UserOutlined />} />
            <Form
                name="login"
                className="login-form"
                requiredMark={false}
                onFinish={onFinish}
                style={{ marginTop: 20 }}
            >
                <Form.Item
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            message: 'O e-mail não está no formato correto.',
                        },
                        { required: true, message: 'O e-mail é obrigatório' }
                    ]}
                    hasFeedback
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        { min: 8, message: 'A senha possui no mínimo 8 caracteres' },
                        { required: true, message: 'A senha é obrigatória' }]}
                    hasFeedback
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Senha"
                    />
                </Form.Item>
                <Form.Item>
                    <Link className="login-form-forgot" onClick={() => setShowForgotPasswordModal(true)}>
                        Esqueci a senha
                    </Link>
                </Form.Item>

                <Form.Item style={{ marginBottom: 0 }}>
                    <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
                        Entrar
                    </Button>
                    <br />
                    {/* ou <span> <Link to={'/register'}>cadastre-se</Link></span> */}
                </Form.Item>
            </Form>
            {/* Modal - Recuperação de senha */}
            <ForgotPassword showModal={showForgotPasswordModal} closeModal={() => setShowForgotPasswordModal(false)} />
        </>
    )
}