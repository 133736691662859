import { useQuery } from '@apollo/client';
import { Empty } from 'antd';
import Chart from 'chart.js';
import React, { useCallback, useEffect, useState } from 'react';
import { GET_ALL_CHARGES_BY_USER } from '../../../graphql/queries/queries';
import * as S from '../styles'
import { convertChargeDataset } from './service';

export interface ChargeChartProps {
    randomKey?: number,
    userId?: number,
    date?: string
}

const ChargeChart = (props: ChargeChartProps) => {
    const [chartConfiguration, setChartConfiguration] = useState<Chart.ChartConfiguration | null>(null);
    
    const { loading, data } = useQuery(GET_ALL_CHARGES_BY_USER, {
        variables: { userId: props.userId || 0, createdAt: props.date },
    });

    useEffect(() => {
        if (data && data?.charges) {
            setChartConfiguration(convertChargeDataset(data.charges, props.date));
        }
    }, [data, props.date]);

    const setChartData = useCallback(() => {
        const ctx: any = document.getElementById(`chargeChart-${props.randomKey}`);
        if (ctx && chartConfiguration) {
            ctx.parentNode.style.height = '400px';
            new Chart(ctx, chartConfiguration);
        }
    }, [chartConfiguration, props.randomKey]);

    useEffect(() => {
        if (chartConfiguration) {
            setChartData();
        }
    }, [chartConfiguration, setChartData]);

    return (
        <>
            <S.ChartWrapper>
                <S.CardWrapper bordered={false} loading={loading}>
                    {data?.charges && data?.charges.length
                        ? <canvas id={`chargeChart-${props.randomKey}`}></canvas>
                        : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Nenhum dado encontrado'} />}
                </S.CardWrapper>
            </S.ChartWrapper>
        </>
    )
}

export default ChargeChart;