import React, { ReactNode } from "react"
import { Tooltip, Typography } from 'antd';
import {
    LeftOutlined
} from '@ant-design/icons';
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { useThemeSwitcher } from "react-css-theme-switcher";

export interface PageTitleProps {
    title: string,
    icon?: ReactNode,
    backButton?: boolean,
    url?: string,
}

const PageTitle = (props: PageTitleProps) => {
    const { Title } = Typography;
    const dispatch = useDispatch()
    const { currentTheme } = useThemeSwitcher();

    const changeRoute = () => {
        if (props.url)
            dispatch(push(props.url));
    }

    return (
        <>
            <Title style={{ fontWeight: 400, color: currentTheme === 'light' ? '#001529' : '#FFFFFF', marginBottom: 0 }} level={3}>
                {props.backButton ? <Tooltip title="Voltar"><LeftOutlined onClick={changeRoute} /></Tooltip> : props.icon}
                <span style={{ marginRight: 10 }}></span>
                {props.title}
            </Title>
        </>
    )
}

export default PageTitle;