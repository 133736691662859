import { useQuery } from "@apollo/client";
import { AutoComplete } from "antd";
import { useEffect, useState } from "react";
import { GET_MANAGER_BY_NAME_AND_COMPANY_ID } from "../../graphql/queries/queries";
import { UserModel } from "../../services/model/role";
import { useDebounce } from "../hooks";

export interface AutoCompleteManagerProps {
    userData: UserModel | null,
    currentManager: { value: string, label: string } | null,
    onSelect: (option: { value: string, label: string }) => void
}

export const AutoCompleteManager = (props: AutoCompleteManagerProps) => {
    const [value, setValue] = useState('');
    const [options, setOptions] = useState<{ value: string, label: string }[]>([]);
    const { data, refetch } = useQuery(GET_MANAGER_BY_NAME_AND_COMPANY_ID, {
        variables: { companyId: Number(props?.userData?.company?.id) || 0, name: '' },
    });
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    useEffect(() => {
        if (data && data.users?.length)
            setOptions(data.users.map((h) => ({ label: h.username, value: h.id.toString() })));
        else
            setOptions([]);
    }, [data]);

    // Effect for API call
    useEffect(
        () => {
            if (props?.userData?.company?.id && debouncedSearchTerm) {
                refetch({ companyId: Number(props?.userData?.company?.id), name: debouncedSearchTerm })
            } else {
                setOptions([]);
            }
        },
        [debouncedSearchTerm, props?.userData?.company?.id, refetch] // Only call effect if debounced search term changes
    );

    useEffect(() => {
        if (props.currentManager) {
            props.onSelect(props.currentManager)
            setValue(props.currentManager.label)
        }
    }, [props.currentManager, props]);

    const onSearch = (searchText: string) => setSearchTerm(searchText);

    const onSelect = (data: string, option: { value: string, label: string }) => {
        console.log('onSelect', data);
        console.log('onSelect', option);
        props.onSelect(option);
    };

    const onChange = (data: any, option: any) => {
        console.log({ option });
        setValue(option.label);
    };

    return (
        <>
            <AutoComplete
                style={{ minWidth: '100%' }}
                value={value}
                options={options}
                onSelect={onSelect}
                onSearch={onSearch}
                onChange={onChange}
                placeholder="Busque o gestor"
            />
        </>
    )
};