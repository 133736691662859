import { useMutation } from "@apollo/client";
import { Button, Form, Input, message, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useRef } from "react";
import { createHierarchyInput } from "../../../../graphql/generated/globalTypes";
import { MUTATION_CREATE_HIERARCHY } from "../../../../graphql/mutations/mutations";

export interface AddCompanyProps {
    showModal: boolean,
    closeModal: () => void,
    onChangeCompany: () => void
}

const AddCompany = (props: AddCompanyProps) => {
    const formRef = useRef<any>(null);

    const [createCompany, { loading: mutationCreateCompanyLoading }] = useMutation(MUTATION_CREATE_HIERARCHY);
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        finishCreateCompany(values);
    };

    const finishCreateCompany = (values: any) => {
        const formData: createHierarchyInput = {
            data: {
                name: values?.name,
                description: values?.description,
                type: values?.type,
                root: true
            }
        }

        createCompany({
            variables: {
                input: formData
            },
        })
            .then(() => {
                message.success('Empresa cadastrada com sucesso');
                props.closeModal();
                props.onChangeCompany();
            })
            .catch(() => {
                message.error('Não foi possível cadastrar a empresa. Entre em contato com TI.')
            });
    }

    const handleOk = () => {
        if (formRef && formRef.current) {
            formRef.current.submit();
        }
    };

    const handleCancel = () => {
        props.closeModal();
    };

    const loading = mutationCreateCompanyLoading;

    return (
        <Modal
            centered
            visible={props.showModal}
            title={`Criar empresa`}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancelar
                </Button>,
                <Button loading={loading} key="submit" type="primary" onClick={handleOk}>
                    Criar
                </Button>
            ]}
        >
            <Form
                ref={formRef}
                name="add-company"
                layout={'vertical'}
                form={form}
                requiredMark={false}
                onFinish={onFinish}
            >
                <Form.Item
                    label="Nome"
                    name="name"
                    rules={[
                        { required: true, message: 'É obrigatório informar o nome' }
                    ]}
                >
                    <Input onChange={(value) => console.log({ value })} />
                </Form.Item>

                <Form.Item
                    label="Descrição"
                    name="description"
                    rules={[
                        { required: true, message: 'É obrigatório informar a descrição' }
                    ]}
                >
                    <TextArea rows={4} autoSize onChange={(value) => console.log({ value })} />
                </Form.Item>

                <Form.Item
                    label="Tipo"
                    name="type"
                    rules={[
                        { required: true, message: 'É obrigatório informar o tipo. Só é permitido letras minúsculas, sem espaço e caracteres especiais.', pattern: RegExp('^[a-z]+$', 'g') }
                    ]}
                >
                    <Input onChange={(value) => console.log({ value })} />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default AddCompany;