import { AxiosResponse } from "axios";
import { push } from "connected-react-router";
import { AuthThunk, LoginFormModel, RoleModel, UserModel } from "../../services/model/role";
import httpClient from "../../utils/axios";
import { setLoading, toggleAlert } from "../common/action";
import { authenticate, logout, setRoles } from "./action"

/* Login */
export const login = (
    loginData: LoginFormModel
): AuthThunk => async (dispatch, state, api) => {
    dispatch(setLoading(true));
    httpClient.post(`${api}/auth/local`, loginData)
        .then((result: AxiosResponse<{ jwt: string, user: UserModel }>) => {
            const { data } = result;
            console.log({ data })
            dispatch(authenticate(data?.jwt, data?.user))
            dispatch(toggleAlert({ showAlert: true, message: 'Login efetuado com sucesso!', timer: 5000 }))
            dispatch(push('/'));
        })
        .catch((error: Response) => {
            console.log({ error });
            if (error?.status === 400)
                dispatch(toggleAlert({ showAlert: true, message: 'Não foi possível fazer o login. Usuário e senha estão inválidos.', timer: 5000, type: "error" }))
            else
                dispatch(toggleAlert({ showAlert: true, message: 'Não foi possível fazer o login. Erro desconhecido.', timer: 5000, type: "error" }))
        })
        .finally(() => dispatch(setLoading(false)));
}

/* Coletando dados do usuário caso aconteça um refresh da página */
export const me = (): AuthThunk => async (dispatch, state, api) => {
    const token = localStorage.getItem('token');
    if (token) {
        httpClient.get(`${api}/users/me`)
            .then((result: AxiosResponse<UserModel>) => {
                const { data } = result;
                dispatch(authenticate(token, data));
            })
            .catch((error: Response) => {
                console.log({ error });
                if (error?.status === 403)
                    dispatch(doLogout('Erro de autorização de acesso, entre me contato com o administrador do sistema.', 'error'));
                else {
                    dispatch(doLogout('Ocorreu um erro ao buscar as informações de usuário, entre me contato com o administrador do sistema.', 'error'));
                }
            })
            .finally(() => dispatch(setLoading(false)));
    } else {
        doLogout();
    }
}

/* Coletando dados de domínio de roles */
export const getRoles = (): AuthThunk => async (dispatch, state, api) => {
    const token = localStorage.getItem('token');
    if (token) {
        httpClient.get(`${api}/users-permissions/roles`)
            .then((result: AxiosResponse<{ roles: Array<RoleModel> }>) => {
                const { data } = result;
                dispatch(setRoles(data?.roles));
            })
            .catch((error: Response) => {
                console.log({ error });
                if (error?.status === 403)
                    dispatch(doLogout('Erro de autorização de acesso, entre me contato com o administrador do sistema.', 'error'));
                else {
                    dispatch(doLogout('Ocorreu um erro ao buscar as informações do domínio de roles, entre me contato com o administrador do sistema.', 'error'));
                }
            })
            .finally(() => dispatch(setLoading(false)));
    } else {
        doLogout();
    }
}

/* Esqueci a senha */
export const forgotPassword = (
    email: string
): AuthThunk => async (dispatch, state, api) => {
    dispatch(setLoading(true));
    httpClient.post(`${api}/auth/forgot-password`, { email })
        .then(() => {
            dispatch(toggleAlert({ showAlert: true, message: 'E-mail de recuperação de senha enviado com sucesso!', timer: 5000 }))
        })
        .catch((error: Response) => {
            console.log({ error });
            dispatch(toggleAlert({ showAlert: true, message: 'Não foi possível enviar e-mail de recuperação.', timer: 5000, type: "error" }))
        }).finally(() => {
            dispatch(setLoading(false));
        });
}

/* Reset de senha */
export const resetPassword = (
    code: string,
    password: string,
    passwordConfirmation: string,
): AuthThunk => async (dispatch, state, api) => {
    dispatch(setLoading(true));
    httpClient.post(`${api}/auth/reset-password`, { code, password, passwordConfirmation })
        .then(() => {
            dispatch(toggleAlert({ showAlert: true, message: 'Senha alterada com sucesso!', timer: 5000 }))
            dispatch(push('/login'))
        })
        .catch((error: Response) => {
            console.log({ error });
            dispatch(toggleAlert({ showAlert: true, message: 'Não foi possível alterar a senha.', timer: 5000, type: "error" }))
        }).finally(() => {
            dispatch(setLoading(false));
        });
}

/* Logout */
export const doLogout = (message = 'Você foi deslogado com sucesso!', type: 'success' | 'error' = 'success'): AuthThunk => async (dispatch, state, api) => {
    dispatch(logout())
    dispatch(toggleAlert({ showAlert: true, message: message, timer: 5000, type: type }))
    dispatch(push('/login'))
}