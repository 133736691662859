import styled from "styled-components";

export const Logo = styled.h1`
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    margin-left: 20px;
    color: white;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 0;
    
    span {
        line-height: 12px;
    }

    span:nth-child(2) {
        margin-top: 2px;
    }
`