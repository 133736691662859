import ContainerWrapper from "../../components/container"

import {
    ToolOutlined,
} from '@ant-design/icons';
import PageTitle from "../../common/title";
import { Button, Col, Divider, Row, Tabs } from "antd";
import { useSelector } from "react-redux";
import { FlowScaleStore } from "../../redux/combineReducers";
import Hierachies from "./hierarchy/hierarchies";
import { CanManageAdministratorsUsers as canManageAdministratorsUsers, GetRolePermissionByRoleType } from "../../services/role";
import { useEffect, useState } from "react";
import { RoleModel, RolePermissionModel } from "../../services/model/role";
import { TeamTable } from "../teams/teams-list/team-table";
import { Register } from "../../components/register";
import Companies from "./company/companies";

const { TabPane } = Tabs;
export const Configuration = () => {
    const userData = useSelector((state: FlowScaleStore) => state.auth.user);
    const [showRegisterModal, setShowRegisterModal] = useState<boolean>(false);
    const [rolePermission, setRolePermission] = useState<RolePermissionModel | undefined>(undefined);

    useEffect(() => {
        if (userData)
            setRolePermission(GetRolePermissionByRoleType((userData?.role as RoleModel)?.type));
    }, [userData]);

    return (
        <ContainerWrapper>
            <Row justify="space-between" align="middle" style={{ marginBottom: 10 }}>
                <Col>
                    {/* Titulo */}
                    <PageTitle title="Configurações" icon={<ToolOutlined />} />
                </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" sm={24} lg={24}>
                    {/* Componente de Configuração de Hierarquias */}
                    {rolePermission && canManageAdministratorsUsers(rolePermission.type)
                        ? <Companies />
                        : <Hierachies />}
                </Col>
                <Col className="gutter-row" sm={24} lg={24}>
                    <Divider />
                    {/* Cadastro de administradores pelo perfil de Onboarding / Administrador */}
                    {showRegisterModal && rolePermission && canManageAdministratorsUsers(rolePermission.type) && <Register
                        title="Cadastrar novo administrador"
                        managerId={userData?.id as number}
                        companyId={Number(userData?.company?.id)}
                        visible={true}
                        onClose={() => setShowRegisterModal(false)}
                    />}
                    {<Tabs defaultActiveKey="1">
                        {userData?.id && rolePermission && canManageAdministratorsUsers(rolePermission.type) && <TabPane tab="Gestão dos Administradores" key="1">
                            <TeamTable
                                managerId={userData.id}
                                subtitle="Usuários administradores e suas respectivas empresas"
                                isManager={true}
                                isOnboarding={true}
                                action={<Button key="configuration-add-user" onClick={() => setShowRegisterModal(true)}>Adicionar usuário</Button>}
                            />
                        </TabPane>}
                    </Tabs>}
                </Col>
            </Row>
        </ContainerWrapper>
    )
}