import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import { collapseMenu } from '../../redux/common/thunk';
import { FlowScaleStore } from '../../redux/combineReducers';
import menuItems, { MenuItemModel } from './menuItems';
import { RoleModel } from '../../services/model/role';
export interface SideMenuProps {
    currentTheme?: string
}

const SideMenu = (props: SideMenuProps) => {
    const dispatch = useDispatch()
    const location = useLocation();
    const [selectedKey, setSelectedKey] = useState<string>('');
    const isMobileResolution = useSelector((state: FlowScaleStore) => state.common.isMd);
    const userData = useSelector((state: FlowScaleStore) => state.auth.user);
    const [userMenu, setUserMenu] = useState<MenuItemModel[]>([]);

    useEffect(() => {
        if (userData)
            setUserMenu(menuItems.filter(m => m.visibleForRoles.find(v => v === (userData.role as RoleModel).type)));
    }, [userData]);

    useEffect(() => {
        userMenu.forEach((item, i) => {
            if (item.route === location.pathname)
                setSelectedKey(i.toString());
        });
    }, [location, userMenu]);

    const changeRoute = (url: string) => {
        dispatch(push(url));
        if (isMobileResolution)
            dispatch(collapseMenu(true));
    }

    return (
        <Menu
            mode="inline"
            style={{ marginTop: 10, border: '0' }}
            selectedKeys={[selectedKey]}
            className={props.currentTheme === 'dark' ? 'site-layout-background-dark' : ''}
        >
            {userMenu && userMenu.length ? userMenu.map((item, index) => {
                return (
                    <Menu.Item
                        key={index}
                        icon={item.icon}
                        onClick={() => changeRoute(item.route)}
                    >
                        {item.name}
                    </Menu.Item>
                );
            }) : null}
        </Menu>
    )
}

export default SideMenu;