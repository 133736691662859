import { MANAGER_ROLE_PERMISSIONS, RoleModel, RolePermissionModel, RoleTypeModel, ROLE_PERMISSIONS } from "./model/role";

export const GetRolePermissionByRoleType = (role: RoleTypeModel): RolePermissionModel | undefined =>
  ROLE_PERMISSIONS.find(r => r.type === role);

export const GetRolePermissionByMultipleRoleTypes = (roles: Array<RoleTypeModel> | undefined) =>
  roles ? ROLE_PERMISSIONS.filter(r => roles.includes(r.type)) : [];

export const GetRolesDomainListByRoleType = (role: RoleTypeModel): RolePermissionModel[] =>
  GetRolePermissionByMultipleRoleTypes(ROLE_PERMISSIONS.find(r => r.type === role)?.canCreate);

export const CanManageUsersPermissionByRoleType = (role: RoleTypeModel): boolean =>
  MANAGER_ROLE_PERMISSIONS.filter(r => r === role).length > 0;

export const CanManageAdministratorsUsers = (role: RoleTypeModel): boolean =>
  role === RoleTypeModel.onboarding;

export const GetRoleIdByType = (roles: RoleModel[], type: RoleTypeModel) =>
  roles.find(r => r.type === type)?.id.toString();

export const GetRolesDomainCanBePromotedListByRoleType = (role: RoleTypeModel): RolePermissionModel[] =>
  GetRolePermissionByMultipleRoleTypes(ROLE_PERMISSIONS.find(r => r.type === role)?.canBePromotedTo);
