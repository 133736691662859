import axios from 'axios';

const httpClient = axios.create();

// Request interceptor for API calls
httpClient.interceptors.request.use(
  async config => {
    const token = localStorage.getItem('token');
    config.headers = { 
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }

    if (config.url?.match(RegExp(/forgot-password/gi)))
      return config;

    if (token) {
      config.headers = {
        ...config.headers,
        'Authorization': `Bearer ${token}`,
      }
    }

    return config;
  },
  error => {
    Promise.reject(error)
});

// Response interceptor for API calls
httpClient.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 403 && !originalRequest._retry) {
    // TODO: Implementar refresh token
    // originalRequest._retry = true;
    // const access_token = await refreshAccessToken();            
    // axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
    // return httpClient(originalRequest);
    return Promise.reject(error);
  }
  return Promise.reject(error);
});

export default httpClient;