import { useQuery } from '@apollo/client';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import PageTitle from '../../../common/title';
import ContainerWrapper from '../../../components/container';
import { GET_USER_DATA } from '../../../graphql/queries/queries';
import ActivitiesList from '../activities-list';

const ReadActivities = () => {
    const { id } = useParams<{ id: string | undefined }>();
    const [userId, setUserId] = useState<number | undefined>(undefined);

    const { data } = useQuery(GET_USER_DATA, {
        variables: { userId: userId },
    });

    useEffect(() => {
        if (id) {
            setUserId(Number(id));
        }
    }, [id]);

    return (
        <ContainerWrapper>
            <Row justify="space-between" align="middle" style={{ marginBottom: 10 }}>
                <Col>
                    {/* Titulo */}
                    {data && data?.user 
                        ? <PageTitle title={`Atividades do usuário:  ${data?.user?.username}`} backButton={true} url={'/teams'} />
                        : null}
                </Col>
            </Row>
            {/* Lista de Atividades */}
            <ActivitiesList userId={userId} readMode={true} />
        </ContainerWrapper>
    )
}

export default ReadActivities;