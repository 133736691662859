import { Button } from "antd";
import React, { useState } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import {
    BulbOutlined,
} from '@ant-design/icons';
import * as S from './styles';

export const ToggleTheme = () => {
    const [isDarkMode, setIsDarkMode] = useState<boolean>(false);
    const { switcher, currentTheme, status, themes } = useThemeSwitcher();

    const toggleTheme = () => {
        const toggle = !isDarkMode;
        setIsDarkMode(toggle);
        switcher({ theme: toggle ? themes.dark : themes.light });
    };

    if (status === "loading") {
        return null;
    }

    return (
        <S.ToggleThemeSwith currentTheme={currentTheme} onClick={toggleTheme}>
            <Button type={"link"} icon={<BulbOutlined />} size={"large"}></Button>
        </S.ToggleThemeSwith>
    )
}