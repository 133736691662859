import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { Provider } from 'react-redux'
import configureStore from './redux/store'
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { ToggleTheme } from './toggleTheme';
import AlertNotification from './common/alert';
import { Feedback } from './components/feedback';
import { PUBLIC_URL } from './config/config';

const themes = {
  dark: `${PUBLIC_URL}/dark-theme.css`,
  light: `${PUBLIC_URL}/light-theme.css`,
};

const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeSwitcherProvider
        themeMap={themes}
        defaultTheme="light"
        insertionPoint="styles-insertion-point"
      >
        <AlertNotification />
        <Feedback />
        <ToggleTheme />
        <App />
      </ThemeSwitcherProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);