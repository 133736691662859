import { Form, Input } from "antd";
import React, { useEffect, useRef } from "react";

export interface RegisterSecurityDataFormProps {
    getFormRef: (formRef: any) => void,
    securityFormData: RegisterSecurityFormData | null,
    setSecurtiyFormData: (data: RegisterSecurityFormData) => void,
}

export interface RegisterSecurityFormData {
    password: string,
    passwordConfirmation: string
}

export const RegisterSecurityDataForm = (props: RegisterSecurityDataFormProps) => {
    const formRef = useRef<any>(null);
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        props.setSecurtiyFormData({ ...values })
    };

    useEffect(() => {
        props.getFormRef(formRef);
    }, [formRef, props]);

    useEffect(() => {
        if (props.securityFormData) {
            const { password, passwordConfirmation } = props.securityFormData;
            form.setFieldsValue({
                password,
                passwordConfirmation
            })
        } else {
            form.setFieldsValue({
                password: null,
                passwordConfirmation: null
            })
        }
    }, [props.securityFormData, form]);

    return (
        <Form
            ref={formRef}
            form={form}
            requiredMark={false}
            name="registerSecurity"
            layout={'vertical'}
            initialValues={{ remember: true }}
            onFinish={onFinish}
        >
            <Form.Item
                label="Senha"
                name="password"
                rules={[
                    { min: 8, message: 'É necessário no mínimo 8 caracteres' },
                    { required: true, message: 'A senha é obrigatória' }]}
                hasFeedback
            >
                <Input.Password />
            </Form.Item>

            <Form.Item
                label="Confirmação de senha"
                name="passwordConfirmation"
                dependencies={['password']}
                rules={[
                    {
                        required: true,
                        message: 'Por favor, confirme a senha',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }

                            return Promise.reject(new Error('A senhas informadas não conferem!'));
                        },
                    }),
                ]}
                hasFeedback
            >
                <Input.Password />
            </Form.Item>
        </Form>
    )
}