import React from 'react'
import { Card, Modal } from 'antd'
import { StepsRegisterForm } from './steps'

export interface RegisterProps {
    title?: string,
    managerId: number,
    companyId: number,
    visible: boolean,
    onClose: () => void
}

export const Register = (props: RegisterProps) => {
    return (
        <Modal
            centered
            visible={props.visible}
            title={props.title ?? "Cadastrar novo usuário"}
            onCancel={props.onClose}
            footer={null}
            width={1000}
        >
            <Card>
                <StepsRegisterForm
                    managerId={props.managerId}
                    companyId={props.companyId}
                    closeModal={props.onClose}
                />
            </Card>
        </Modal>
    )
}