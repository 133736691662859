import { useQuery } from "@apollo/client";
import { Button, PageHeader, Space, Table, Tag, Tooltip } from "antd";
import React, { Fragment, useState } from "react";
import { GET_USER_CREATED_FOR_COMPANY, GET_USER_TEAM_BY_MANAGER } from "../../../../graphql/queries/queries";
import { RoleModel, UserModel } from "../../../../services/model/role";

import {
    SettingOutlined,
    PieChartOutlined
} from '@ant-design/icons';
import moment from "moment";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { GetRolePermissionByRoleType } from "../../../../services/role";
import { UpdateUserModal } from "../../../../components/user/update";

export interface TeamTableProps {
    subtitle: string,
    managerId: number,
    isManager: boolean,
    isOnboarding?: boolean,
    action?: React.ReactNode
}

export const TeamTable = (props: TeamTableProps) => {
    const dispatch = useDispatch()
    const { loading, data } = useQuery(props.isOnboarding
        ? GET_USER_CREATED_FOR_COMPANY
        : GET_USER_TEAM_BY_MANAGER, {
        variables: { manager: props.managerId },
    });
    const [showUpdateUserModel, setShowUpdateUserModal] = useState<boolean>(false);
    const [currentUserData, setCurrentUserData] = useState<UserModel | null>(null);

    const openUserUpdateModal = (userData: UserModel) => {
        setCurrentUserData(userData);
        setShowUpdateUserModal(true);
    }

    const columns: any = [
        {
            title: 'Usuário',
            dataIndex: 'username',
            key: 'username',
            width: 55,
            ellipsis: {
                showTitle: false,
            },
            render: username => (
                <Tooltip placement="topLeft" title={username}>
                    {username}
                </Tooltip>
            ),
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
            width: 100,
            ellipsis: {
                showTitle: false,
            },
            render: email => (
                <Tooltip placement="topLeft" title={email}>
                    {email}
                </Tooltip>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'blocked',
            key: 'blocked',
            width: 75,
            render: (blocked: boolean) => (
                <>
                    {blocked ? <Tag color="red" key={Math.random()}>
                        Inativo
                    </Tag> : <Tag color="green" key={Math.random()}>
                        Ativo
                    </Tag>}
                </>
            )
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            width: 70,
            render: (tag: RoleModel) => {
                const role = GetRolePermissionByRoleType(tag.type);
                return (
                    <Tag key={role?.type} color={role?.badgeColor}>{role?.name}</Tag>
                );
            }
        },
        {
            title: 'Adicionado em',
            dataIndex: 'created_at',
            key: 'created_at',
            responsive: ['md'],
            ellipsis: true,
            width: 100,
            render: (createdAt: string) => (
                <span>{moment(createdAt).format('DD/MM/YYYY - HH:mm:ss')}</span>
            )
        },
        {
            title: 'Atualizado em',
            dataIndex: 'updated_at',
            key: 'updated_at',
            responsive: ['md'],
            ellipsis: true,
            width: 100,
            render: (updatedAt: string) => (
                <span>{moment(updatedAt).format('DD/MM/YYYY - HH:mm:ss')}</span>
            )
        },
    ];

    if (props.isManager) {
        const managerOptions = [
            {
                title: '',
                key: 'operation',
                fixed: 'right',
                width: 120,
                render: (t: any, r: any) => (
                    <Space style={{ float: 'right' }} size="middle">
                        <Tooltip title={'Gerenciar'} placement={"left"}>
                            <Button
                                onClick={() => openUserUpdateModal(r)}
                                type={'default'}
                                icon={<SettingOutlined />}
                                title={'Gerenciar'}
                            />
                        </Tooltip>
                        {!props.isOnboarding
                            ? <Tooltip title={'Visualizar progresso'} placement={"left"}>
                                <Button
                                    onClick={() => openUserActivitiesPage(r)}
                                    type={'primary'}
                                    icon={<PieChartOutlined />}
                                    title={'Exibir dados do usuário'}
                                />
                            </Tooltip>
                            : null}

                    </Space>
                )
            },
        ]

        columns.push(...managerOptions);
    }

    if (props.isOnboarding) {
        const onboardingOptions = {
            title: 'Empresa',
            dataIndex: ['company'],
            width: 55,
            ellipsis: {
                showTitle: false,
            },
            render: company => (
                <Tooltip placement="topLeft" title={company?.name}>
                    {company?.name ?? 'Sem empresa'}
                </Tooltip>
            ),
        };

        columns.splice(1, 0, onboardingOptions);
    } else {
        const defaultOptions = {
            title: 'Setor',
            dataIndex: ['hierarchy'],
            width: 55,
            ellipsis: {
                showTitle: false,
            },
            render: hierarchy => (
                <Tooltip placement="topLeft" title={hierarchy?.name}>
                    {hierarchy?.name ?? 'Sem setor'}
                </Tooltip>
            ),
        };

        columns.splice(1, 0, defaultOptions);
    }

    const openUserActivitiesPage = (user: UserModel) => {
        dispatch(push(`teams/${user.id}`));
    }

    return (
        <>
            {/* Modal de update do usuário */}
            <UpdateUserModal
                managerId={props.managerId}
                isOnboarding={props.isOnboarding}
                show={showUpdateUserModel}
                userData={currentUserData}
                closeModal={() => setShowUpdateUserModal(false)}
            />
            {/* Cabeçalho */}
            <PageHeader
                className="site-page-header"
                subTitle={props.subtitle}
                style={{ padding: '0 0 10px 0' }}
                extra={[<Fragment key="actions">{props?.action}</Fragment>]}
            ></PageHeader>
            {/* Lista de membros */}
            <Table
                loading={loading}
                columns={columns}
                rowKey={record => record?.id}
                dataSource={data ? data.users : []}
                locale={{
                    emptyText: 'Nenhum usuário encontrado.'
                }}
                scroll={{ x: 300 }} sticky
            />
        </>
    )
}