import { initAlertModel } from "./model";
import CommonState from "./state";
import { CommonTypes } from "./types";

export const initialState: CommonState = {
    alert: initAlertModel,
    loading: false,
    collapsed: false,
    isMd: false
}

export function commonReducer(
    state = initialState,
    action: CommonTypes
): CommonState {
    switch (action.type) {
        case "TOGGLE_ALERT":
            return {
                ...state, alert: {
                    ...initAlertModel,
                    ...action.alert
                }
            }
        case "SET_LOADING":
            return {
                ...state, loading: action.loading
            }
        case "SET_COLLAPSE":
            return {
                ...state, collapsed: action.collapsed
            }
        case "SET_MOBILE_RESOLUTION":
            return {
                ...state, isMd: action.isMd
            }
        default:
            return state;
    }
}