import { Form, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RoleModel } from "../../../services/model/role";
import { FlowScaleStore } from "../../../redux/combineReducers";
import { CanManageAdministratorsUsers as canManageAdministratorsUsers } from "../../../services/role";
import { AutoCompleteCompany } from "../../../common/autocompleteCompany";
import { SelectPermissions } from "../../../common/selectPermissions";
import { AutoCompleteHierarchy } from "../../../common/autocompleteHierarchy";
export interface RegisterManagerDataFormProps {
    getFormRef: (formRef: any) => void,
    managerFormData: RegisterManagerFormData | null,
    setManagerFormData: (data: RegisterManagerFormData) => void,
}

export interface RegisterManagerFormData {
    roleType: number,
    hierarchy: string,
    fromOnboarding: boolean
}

export const RegisterManagerDataForm = (props: RegisterManagerDataFormProps) => {
    const formRef = useRef<any>(null);
    const [form] = Form.useForm();
    const [selectedHierarchy, setSelectedHierarchy] = useState<string | null>(null);
    const [selectedRole, setSelectedRole] = useState<string | null>(null);
    const [isOnboarding, setIsOnboarding] = useState<boolean>(false);
    const userData = useSelector((state: FlowScaleStore) => state.auth.user);

    useEffect(() => {
        if (canManageAdministratorsUsers((userData?.role as RoleModel)?.type)) {
            setIsOnboarding(true);
        }
    }, [userData]);

    const onFinish = (values: any) => {
        if (!selectedRole) {
            message.warn('É necessário informar o tipo de perfil para continuar.');
            return;
        }

        if (!selectedHierarchy) {
            message.warn('É necessário informar um setor para continuar.');
            return;
        } else {
            values = {
                ...values,
                roleType: selectedRole,
                hierarchy: selectedHierarchy,
                fromOnboarding: isOnboarding
            }
        }

        console.log({ selectedRole: selectedRole, selectedHierarchy: selectedHierarchy })
        props.setManagerFormData({ ...values })
    };

    useEffect(() => {
        props.getFormRef(formRef);
    }, [formRef, props]);

    useEffect(() => {
        if (props.managerFormData) {
            const { roleType, hierarchy } = props.managerFormData;
            form.setFieldsValue({
                roleType,
                hierarchy
            })
            setSelectedRole(roleType.toString())
            setSelectedHierarchy(hierarchy)
        } else {
            form.setFieldsValue({
                roleType: null,
                hierarchy: null
            })
            setSelectedRole(null)
            setSelectedHierarchy(null)
        }
    }, [props.managerFormData, form]);

    return (
        <Form
            ref={formRef}
            form={form}
            requiredMark={false}
            name="registerManager"
            layout={'vertical'}
            initialValues={{ remember: true }}
            onFinish={onFinish}
        >
            <Form.Item name="roleType" label="Selecionar tipo de perfil" required>
                <SelectPermissions
                    onSelect={(e) => setSelectedRole(e)} />
            </Form.Item>
            <Form.Item name="hierarchy" label={isOnboarding ? 'Selecione a empresa' : 'Selecionar o setor'} required>
                {isOnboarding
                    ? <AutoCompleteCompany
                        onSelect={((option: { value: string, label: string }) => setSelectedHierarchy(option.value))} />
                    : <AutoCompleteHierarchy
                        companyId={Number(userData?.company?.id)}
                        onSelect={((option: { value: string, label: string }) => setSelectedHierarchy(option.value))}
                    />}
            </Form.Item>
        </Form>
    )
}