import { Button, Tooltip } from "antd";
import React from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import {
    QuestionCircleOutlined,
} from '@ant-design/icons';
import * as S from './styles';

export const Feedback = () => {
    const { currentTheme } = useThemeSwitcher();

    return (
        <S.FeedbackHelp currentTheme={currentTheme}>
            <Tooltip placement="left" title={"Caso tenha alguma sugestão, ficaremos felizes em conhecê-la! Clique aqui para entrar em contato."}>
                <Button
                    type={"link"}
                    size={"large"}
                    style={{ fontSize: 12 }}
                    icon={<QuestionCircleOutlined />}
                    target="_blank"
                    href="mailto:suporteflowscale@atihc.co?subject=Suporte - Flow Scale"
                ></Button>
            </Tooltip>
        </S.FeedbackHelp>
    )
}