import { Layout } from "antd";
import { Content, Footer } from "antd/lib/layout/layout";
import Sider, { CollapseType } from "antd/lib/layout/Sider";

import React, { ReactNode } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { useDispatch, useSelector } from "react-redux";
import { FlowScaleStore } from "../../redux/combineReducers";
import { collapseMenu, mobileResolution } from "../../redux/common/thunk";
import SideMenu from "../menu";
import ContainerHeader from "./header";
import './index.css';

export interface ContainerWrapperProps {
  children: ReactNode;
}

const ContainerWrapper = (props: ContainerWrapperProps) => {
  const collapseWidthStatus = useSelector((state: FlowScaleStore) => state.common.isMd);
  const collapseState = useSelector((state: FlowScaleStore) => state.common.collapsed);
  const { currentTheme } = useThemeSwitcher();
  const dispatch = useDispatch();

  const onBreakpoint = (status: boolean) => {
    dispatch(mobileResolution(status));
  }

  const onCollapse = (status: boolean, type: CollapseType) => {
    if (type === 'clickTrigger')
      dispatch(collapseMenu(status));
    else if (type === 'responsive' && status === true)
      dispatch(collapseMenu(status));
    else
      return;
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <ContainerHeader />
      <Layout hasSider>
        <Sider
          collapsible
          className={currentTheme === 'light' ? 'site-layout-background' : 'site-layout-background-dark'}
          collapsed={collapseState}
          collapsedWidth={collapseWidthStatus ? 0 : 80}
          onBreakpoint={onBreakpoint}
          onCollapse={onCollapse}
          breakpoint="md">
          <SideMenu currentTheme={currentTheme} />
        </Sider>
        <Layout className="site-layout">
          <Content>
            <div style={{ padding: 20, minHeight: 360 }}>
              {props.children}
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>Flow Scale ©{new Date().getFullYear()}. Criado e desenvolvido por Darlan Caruso.</Footer>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default ContainerWrapper;
