import AuthState from "./state";
import { AuthTypes } from "./types";

export const initialState: AuthState = {
    jwt: '',
    user: null,
    roles: []
}

export function authReducer(
    state = initialState,
    action: AuthTypes
): AuthState {
    switch (action.type) {
        case "AUTHENTICATE":
            return { ...state, jwt: action.jwt, user: action.user }
        case "SET_ROLES":
            return { ...state, roles: action.roles }
        case "LOGOUT":
            return { ...state, jwt: '' }
        default:
            return state;
    }
}