import { Form, Input } from "antd";
import React, { useEffect, useRef } from "react";

export interface RegisterBasicDataFormProps {
    getFormRef: (formRef: any) => void,
    basicFormData: RegisterBasicFormData | null,
    setBasicFormData: (data: RegisterBasicFormData) => void,
}

export interface RegisterBasicFormData {
    email: string,
    username: string
}

export const RegisterBasicDataForm = (props: RegisterBasicDataFormProps) => {
    const formRef = useRef<any>(null);
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        props.setBasicFormData({ ...values })
    };

    useEffect(() => {
        props.getFormRef(formRef);
    }, [formRef, props]);

    useEffect(() => {
        if (props.basicFormData) {
            const { email, username } = props.basicFormData;
            form.setFieldsValue({
                email,
                username
            })
        } else {
            form.setFieldsValue({
                email: null,
                username: null
            })
        }
    }, [props.basicFormData, form]);

    return (
        <Form
            ref={formRef}
            form={form}
            requiredMark={false}
            name="registerBasic"
            layout={'vertical'}
            initialValues={{ remember: true }}
            onFinish={onFinish}
        >
            <Form.Item
                label="Nome de usuário"
                name="username"
                rules={[{ required: true, message: 'O nome de usuário é obrigatório' }]}
                hasFeedback
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="E-mail"
                name="email"
                rules={[
                    { type: 'email', message: 'O e-mail está inválido' },
                    { required: true, message: 'O e-mail é obrigatório' }]}
                hasFeedback
            >
                <Input />
            </Form.Item>
        </Form>
    )
}