import { useMutation } from "@apollo/client";
import { Button, Form, Input, InputNumber, message, Modal } from "antd";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { GetActivities_activities as Activity } from "../../../graphql/generated/GetActivities";
import { createActivityInput, updateActivityInput } from "../../../graphql/generated/globalTypes";
import { MUTATION_CREATE_ACTIVITY, MUTATION_UPDATE_ACTIVITY } from "../../../graphql/mutations/mutations";
import { GET_ACTIVITIES_BY_USER_ID } from "../../../graphql/queries/queries";
import { FlowScaleStore } from "../../../redux/combineReducers";

export interface AddActivityProps {
    activity?: Activity | null,
    showModal: boolean,
    closeModal: () => void,
    onCreateActivity: () => void
}

const AddActivity = (props: AddActivityProps) => {
    const formRef = useRef<any>(null);
    const userData = useSelector((state: FlowScaleStore) => state.auth.user);

    const [createActivity, { loading: mutationCreateActivityLoading }] = useMutation(MUTATION_CREATE_ACTIVITY);
    const [updateActivity, { loading: mutationUpdateActivityLoading }] = useMutation(MUTATION_UPDATE_ACTIVITY);
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        if (props.activity)
            finishUpdateActivity(values);
        else
            finishCreateActivity(values);

        props.onCreateActivity();
    };

    const finishCreateActivity = (values: any) => {
        const formData: createActivityInput = {
            data: {
                challenge: values?.challenge,
                competence: values?.competence,
                name: values?.activity,
                user: userData?.id.toString()
            }
        }

        createActivity({
            variables: {
                input: formData
            },
            refetchQueries: [
                {
                    query: GET_ACTIVITIES_BY_USER_ID,
                    variables: {
                        userId: userData?.id
                    }
                }
            ]
        })
            .then(() => {
                message.success('Atividade cadastrada com sucesso');
                props.closeModal();
            })
            .catch(() => {
                message.error('Não foi possível cadastrar a atividade')
            });
    }

    const finishUpdateActivity = (values: any) => {
        const formData: updateActivityInput = {
            data: {
                challenge: values?.challenge,
                competence: values?.competence,
                name: values?.activity
            },
            where: {
                id: (props.activity?.id as string).toString()
            }
        }

        updateActivity({
            variables: {
                input: formData
            },
            refetchQueries: [
                {
                    query: GET_ACTIVITIES_BY_USER_ID,
                    variables: {
                        userId: userData?.id
                    }
                }
            ]
        })
            .then(() => {
                message.success('Atividade atualizada com sucesso');
                props.closeModal();
            }).catch(() => {
                message.error('Não foi possível atualizar a atividade')
            });
    }

    const handleOk = () => {
        if (formRef && formRef.current) {
            formRef.current.submit();
        }
    };

    const handleCancel = () => {
        props.closeModal();
    };

    useEffect(() => {
        if (props.activity) {
            const { name, challenge, competence } = props.activity;
            form.setFieldsValue({
                activity: name,
                challenge: challenge,
                competence: competence
            })
        } else {
            form.setFieldsValue({
                activity: null,
                challenge: null,
                competence: null
            })
        }
    }, [props.activity, form]);

    const loading = mutationCreateActivityLoading || mutationUpdateActivityLoading;

    return (
        <Modal
            centered
            visible={props.showModal}
            title="Adicionar atividade"
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancelar
                </Button>,
                <Button loading={loading} key="submit" type="primary" onClick={handleOk}>
                    Adicionar
                </Button>,
            ]}
        >
            <Form
                ref={formRef}
                name="add-activity"
                layout={'vertical'}
                form={form}
                requiredMark={false}
                onFinish={onFinish}
            >
                <Form.Item
                    label="Atividade"
                    name="activity"
                    rules={[
                        { required: true, message: 'É obrigatório informar o nome' }
                    ]}
                >
                    <Input value={props.activity?.name || ''} onChange={(value) => console.log({ value })} />
                </Form.Item>

                <Form.Item
                    label="Desafio"
                    name="challenge"
                    rules={[
                        { min: 0, max: 10, type: "number", message: "Só é válido números entre 0 e 10." },
                        { required: true, message: 'É obrigatório informar valor do desafio.' }
                    ]}
                >
                    <InputNumber />
                </Form.Item>

                <Form.Item
                    label="Competência"
                    name="competence"
                    rules={[
                        { min: 0, max: 10, type: "number", message: "Só é válido números entre 0 e 10." },
                        { required: true, message: 'É obrigatório informar valor da competência.' }
                    ]}
                >
                    <InputNumber />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default AddActivity;