import Chart from "chart.js";
import { GetActivities_activities as Activity } from '../../../graphql/generated/GetActivities';
import moment, { Moment } from 'moment-timezone';

export enum ChartOptions {
    LastFifteenDays = 0,
    LastMonth = 1,
    CustomDate = 2
}

export interface CosolidateActivity {
    competenceAverage: number,
    challengeAverage: number
}

export interface ChartActivity extends Activity, CosolidateActivity { }

export const createdAtDate = (chartOption: ChartOptions, date?: Moment): string => {
    switch (chartOption) {
        case ChartOptions.LastFifteenDays:
            return moment().tz('America/Sao_Paulo').subtract(15, 'days').format('YYYY-MM-DD');
        case ChartOptions.LastMonth:
            return moment().tz('America/Sao_Paulo').subtract(1, 'months').format('YYYY-MM-DD');
        case ChartOptions.CustomDate:
            return date ? date.format('YYYY-MM-DD') : moment().tz('America/Sao_Paulo').format('YYYY-MM-DD');
    }
}

export const getFirstLineData = () => {
    const linePoints = [];
    for (var i = 0; i <= 20; i++) {
        linePoints.push({
            x: i,
            y: i + 1,
            r: 1
        } as never);
    }

    return linePoints;
}

export const getSecondLineData = () => {
    const linePoints = [];
    for (var i = 0; i <= 20; i++) {
        linePoints.push({
            x: i,
            y: i - 1,
            r: 1
        } as never);
    }

    console.log({ linePoints })

    return linePoints;
}

export const convertActivitiesChartDatasetsByDay = (activities: Activity[], filterDate?: string): Chart.ChartConfiguration | null => {
    if (!activities.length) return null;
    // const isConsolidatedByMonth = Math.round(moment.duration(moment().tz('America/Sao_Paulo').diff(moment(filterDate))).asMonths()) > 1 ? true : false;

    function extractGroupsByDate() {
        var groups: any = {};
        activities.forEach((val: Activity) => {
            // var date = isConsolidatedByMonth ? new Date(val.created_at).getMonth() + 1 : val.created_at.split('T')[0];
            var date = val.created_at.split('T')[0];
            if (date in groups) {
                groups[date].push(val);
            } else {
                groups[date] = new Array(val);
            }
        });

        return groups;
    }

    const groups = extractGroupsByDate();
    function calculateAvarageOfGroup() {
        let groupsEntries: [string, ChartActivity | any][] = Object.entries(groups);

        // groupsEntries.forEach((group: any) => {
        //     let groupData = [...group[1]];

        //     let i = groupData.length;
        //     while (i > 0) {
        //         groupData.splice(-1, 1);
        //         if (groupData.length > 0)
        //             groupsEntries.push([group[0], [...groupData]]);
        //         i--;
        //     }
        // });

        groupsEntries.forEach((group: any) => {
            const groupData = group[1];
            groupData.competenceAverage = Math.round(groupData.reduce((a: number, b: Activity) => a + b.competence, 0) / groupData.length);
            groupData.challengeAverage = Math.round(groupData.reduce((a: number, b: Activity) => a + b.challenge, 0) / groupData.length);
            group[1] = groupData;
        });

        return groupsEntries;
    }

    const groupsEntriesData = calculateAvarageOfGroup();
    const groupsChartDatasets: Chart.ChartDataSets[] = [];
    function convertGroupsDatasets() {
        groupsEntriesData.forEach((groups) => {
            // const labelData: string = isConsolidatedByMonth ? MonthParse[Number(groups[0])] : moment(groups[0] as string).format('DD/MM');
            const labelData: string = moment(groups[0] as string).format('DD/MM');
            const chartPoints: Chart.ChartPoint = {
                x: groups[1].competenceAverage,
                y: groups[1].challengeAverage,
                r: 20
            }

            groupsChartDatasets.push({
                label: labelData,
                data: [chartPoints],
                backgroundColor: ['rgba(54, 162, 235, 0.5)'],
                borderColor: ['rgba(54, 162, 235, 1)'],
            })
        });
    }
    convertGroupsDatasets();

    const chartConfiguration: Chart.ChartConfiguration = {
        type: 'bubble',
        data: {
            datasets: [
                ...groupsChartDatasets,
                {
                    data: [...getFirstLineData()],
                    type: 'line',
                    backgroundColor: 'transparent',
                    borderColor: ['rgba(54, 162, 235, 0.2)']
                },
                {
                    data: [...getSecondLineData()],
                    type: 'line',
                    backgroundColor: 'transparent',
                    borderColor: ['rgba(54, 162, 235, 0.2)']
                }
            ],
        },
        options: {
            plugins: {
                datalabels: {
                    anchor: function (context: any) {
                        return 'center';
                    },
                    align: function (context: any) {
                        return 'center';
                    },
                    color: function (context: any) {
                        return 'white';
                    },
                    formatter: function (value: any, context: any) {
                        if (context.dataset.type === 'line') return '';
                        return context.dataset.label;
                    },
                    font: {
                        weight: 'normal'
                    },
                    offset: 2,
                    padding: 0
                },
            },
            // Core options
            aspectRatio: 5 / 3,
            layout: {
                padding: 16
            },
            legend: {
                display: false,
                align: 'end'
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: 'Challenge'
                        },
                        ticks: {
                            min: 0,
                            max: 10,
                            beginAtZero: true
                        }
                    }
                ],
                xAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: 'Competence'
                        },
                        ticks: {
                            min: 0,
                            max: 10,
                            beginAtZero: true
                        }
                    }
                ]
            }
        }
    };

    console.log(groups);
    console.log(Object.entries(groups));
    console.log(groupsEntriesData);
    console.log(chartConfiguration);

    return chartConfiguration;
}