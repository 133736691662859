import { NestedHierarchy } from "../../../../redux/hierarchy/state";
import { ReactElement } from "react";
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Tooltip } from "antd";
export interface DataNode {
    title: string;
    key: number;
    icon?: ReactElement,
    isLeaf?: boolean;
    children?: DataNode[];
    data: NestedHierarchy
}

export const initTreeData: DataNode[] = [];
const iconStyle = {
    width: 16,
    height: 16,
    marginRight: 6
};

export const parseHierarchyToTreeData = (hierarchies: Array<NestedHierarchy>): DataNode[] => {
    return hierarchies && hierarchies?.length
        ? hierarchies.map(h => ({
            title: h.name,
            key: Number(h.id),
            isLeaf: true,
            children: [],
            data: h,
            icon: h.enabled
                ? <Tooltip placement="left" title="Ativa">
                    <CheckCircleTwoTone style={iconStyle} twoToneColor="#52c41a" />
                </Tooltip>
                : <Tooltip placement="left" title="Desativada">
                    <CloseCircleTwoTone style={iconStyle} twoToneColor="#eb2f96" />
                </Tooltip>
        } as DataNode))
        : [];
}