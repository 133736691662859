import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import { Tooltip } from "antd";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";

const ProfileBadge = () => {
    const dispatch = useDispatch();

    const changeRoute = (url: string) => {
        dispatch(push(url));
    }

    return (
        <>
            <Tooltip title={`Editar perfil`}>
                <EditOutlined
                    style={{ color: 'white', marginLeft: 5, cursor: 'pointer' }}
                    onClick={() => changeRoute('/profile')} />
            </Tooltip>
        </>
    )
}

export default ProfileBadge;