import { useSelector } from 'react-redux'
import { getRoles, me } from '../redux/auth/thunk';
import { FlowScaleStore } from '../redux/combineReducers';

export const GetUserData = (dispatch: any) => {
    const userData = useSelector((state: FlowScaleStore) => state.auth.user);
    const roles = useSelector((state: FlowScaleStore) => state.auth.roles);

    if (!userData)
        dispatch(me());

    if (!roles?.length)
        dispatch(getRoles());

    return userData;
}