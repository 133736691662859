import { Alert, Empty, Tree } from "antd";
import { MutableRefObject, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FlowScaleStore } from "../../../../redux/combineReducers";
import { HierarchyThunk } from "../../../../redux/hierarchy/model";
import { NestedHierarchy } from "../../../../redux/hierarchy/state";
import { changeHierarchiesByRelatedId } from "../../../../redux/hierarchy/thunk";
import { findHierarchyByRelatedId, initTreeData, parseHierarchyToTreeData } from "./service";

interface HierarchyListProps {
    companyId: number | null,
    currentHierarchy?: NestedHierarchy | null,
    onSelectHiearchy: (hierarchy: NestedHierarchy) => void,
    triggerChangeHierarchy: MutableRefObject<(companyId: number) => void>
}

export const HierarchyList = (props: HierarchyListProps) => {
    const dispatch = useDispatch();
    const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
    const [treeData, setTreeData] = useState(initTreeData);
    const hierarchies = useSelector((state: FlowScaleStore) => state.hierarchy.hierarchies);
    const dispatchCallback = useCallback((func: HierarchyThunk) => dispatch(func), [dispatch]);
    const fetchHierarchiesByRelatedId = useCallback((companyId: number) =>
        dispatchCallback(changeHierarchiesByRelatedId(companyId)),
        [dispatchCallback]);
    const fetchHierarchiesByRelatedIdCallback = useCallback(() =>
        fetchHierarchiesByRelatedId(props.companyId as number),
        [fetchHierarchiesByRelatedId, props.companyId]);

    useEffect(() => {
        fetchHierarchiesByRelatedIdCallback();
    }, [fetchHierarchiesByRelatedIdCallback]);

    /* Ao carregar hierarquias */
    useEffect(() => {
        setTreeData(parseHierarchyToTreeData(hierarchies));
    }, [hierarchies]);

    /* Limpa a hierarquia selecionada */
    useEffect(() => {
        if (!props.currentHierarchy) setSelectedKeys([]);
    }, [props.currentHierarchy])

    /* Atualiza a lista quando houver alguma modificação externa */
    useEffect(() => {
        props.triggerChangeHierarchy.current = fetchHierarchiesByRelatedIdCallback
    }, [props.triggerChangeHierarchy, fetchHierarchiesByRelatedIdCallback]);

    const onSelect = (selectedKeysValue: React.Key[], info: any) => {
        setSelectedKeys(selectedKeysValue);
        const hierarchy = findHierarchyByRelatedId(treeData, Number(selectedKeysValue[0]));

        if (hierarchy)
            props.onSelectHiearchy(hierarchy);
    };

    return (
        <>
            {treeData && treeData.length
                ? <>
                    <Alert message="Clique no nome da hierarquia desejada para criar uma nova ramificação." type="info" style={{ marginBottom: 15, zIndex: 1 }} showIcon />
                    <Tree
                        showLine={{ showLeafIcon: false }}
                        showIcon
                        defaultExpandAll
                        onSelect={onSelect}
                        selectedKeys={selectedKeys}
                        treeData={treeData} />
                </>
                : <Empty style={{ padding: 50 }} image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Nenhuma hierarquia cadastrada. Adicione uma nova clicando no botão acima.'} />}
        </>
    )
}