import { gql } from '@apollo/client'

export const MUTATION_REGISTER = gql`
  mutation MutationRegister($input: UsersPermissionsRegisterInput!) {
    register(input: $input) {
      jwt
    }
  }
`

export const MUTATION_CREATE_ACTIVITY = gql`
  mutation MutationCreateActivity($input: createActivityInput) {
    createActivity(input: $input) {
        activity {
          name
          challenge
          competence
        }
    }
  }
`

export const MUTATION_DELETE_ACTIVITY = gql`
  mutation MutationDeleteActivity($input: deleteActivityInput) {
    deleteActivity(input: $input) {
      activity {
        id
      }
    }
  }
`

export const MUTATION_CREATE_USER = gql`
  mutation MutationCreateUser($input: createUserInput) {
    createUser(input: $input) {
      user {
        username
        email
        role {
          id
          name
        }
      }
    }
  }
`

export const MUTATION_UPDATE_USER = gql`
  mutation MutationUpdateUser($input: updateUserInput) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`

export const MUTATION_MARK_NOTIFICATION_AS_VISUALIZED = gql`
  mutation MutationMarkNotificationAsVisualized($input: updateNotificationInput) {
    updateNotification(input: $input) {
      notification {
        visualized
      }
    }
  }
`

export const MUTATION_CREATE_CHARGE_BY_USER = gql`
  mutation MutationCreateChargeByUser($input: createChargeInput) {
    createCharge(input: $input) {
      charge {
        id
        updated_at
        charge
        active
      }
    }
  }
`

export const MUTATION_UPDATE_CHARGE_BY_USER = gql`
  mutation MutationUpdateChargeByUser($input: updateChargeInput) {
    updateCharge(input: $input) {
      charge {
        id
        updated_at
        charge
        active
      }
    }
  }
`

export const MUTATION_CREATE_ACTIVITY_HISTORY = gql`
  mutation MutationSaveActivityHistory($input: createActivityHistoryInput) {
    createActivityHistory(input: $input) {
      activityHistory {
        activity_id {
          id
        }
        started_at
        finished_at
        competence
        challenge
        lastname
      }
    }
  }
`

export const MUTATION_UPDATE_ACTIVITY = gql`
  mutation MutationUpdateActivity($input: updateActivityInput) {
    updateActivity(input: $input) {
      activity {
        id
        challenge
        competence
      }
    }
  }
`

export const MUTATION_CREATE_HIERARCHY = gql`
  mutation MutationCreateHierarchy($input: createHierarchyInput) {
    createHierarchy(input: $input) {
      hierarchy {
        name
        description
        type
        related {
          id
        }
      }
    }
  }
`

export const MUTATION_UPDATE_HIERARCHY = gql`
  mutation MutationUpdateHierarchy($input: updateHierarchyInput) {
    updateHierarchy(input: $input) {
      hierarchy {
        name
        description
        type
      }
    }
  }
`

export const MUTATION_DELETE_HIERARCHY = gql`
  mutation MutationDeleteHierarchy($input: deleteHierarchyInput) {
    deleteHierarchy(input: $input) {
      hierarchy {
        id
      }
    }
  }
`

