import React, { useEffect, useState } from "react"
import ContainerWrapper from "../../components/container"

import {
    TeamOutlined,
} from '@ant-design/icons';
import PageTitle from "../../common/title";
import TeamsList from "./teams-list";
import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import { RoleModel, RolePermissionModel, RoleTypeModel, UserModel } from "../../services/model/role";
import ActivitiesChart from "../../components/charts/activitiesChart";
import { GET_ACTIVITIES_FROM_MEMBERS_BY_CREATED_AT_DATE } from "../../graphql/queries/queries";
import { FlowScaleStore } from "../../redux/combineReducers";
import { GetRolePermissionByRoleType } from "../../services/role";

export const Teams = () => {
    const userData = useSelector((state: FlowScaleStore) => state.auth.user);
    const [rolePermission, setRolePermission] = useState<RolePermissionModel | undefined>(undefined);

    useEffect(() => {
        if (userData) 
            setRolePermission(GetRolePermissionByRoleType((userData?.role as RoleModel)?.type));
    }, [userData]);
    
    return (
        <ContainerWrapper>
            <Row justify="space-between" align="middle" style={{ marginBottom: 10 }}>
                <Col>
                    {/* Titulo */}
                    <PageTitle title="Equipe" icon={<TeamOutlined />} />
                    {userData?.manager 
                        ? <p style={{ marginBottom: 0 }}><strong>Líder: </strong>{(userData.manager as UserModel).username}</p> 
                        : <p style={{ marginBottom: 0 }}>Você não possui nenhum líder.</p>}
                </Col>
            </Row>
            {rolePermission && rolePermission.type === RoleTypeModel.lider ?
                <ActivitiesChart
                    title="Análise das atividades do seu time"
                    randomKey={new Date().getTime()}
                    userId={userData?.id}
                    query={GET_ACTIVITIES_FROM_MEMBERS_BY_CREATED_AT_DATE}
                    variables={{ managerId: userData?.id }}
                /> : null
            }
            {/* Lista de membros da equipe */}
            <TeamsList userData={userData} />
        </ContainerWrapper>
    )
}