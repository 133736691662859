import PageTitle from "../../../../common/title";
import { KeyOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { FlowScaleStore } from "../../../../redux/combineReducers";
import { resetPassword } from "../../../../redux/auth/thunk";
import { useLocation } from "react-router";

const ResetPasswordForm = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const loading = useSelector((state: FlowScaleStore) => state.common.loading);

    const onFinish = (values: any) => {
        const code = new URLSearchParams(location?.search).get('code');
        if (code)
            dispatch(resetPassword(code, values.password, values.passwordConfirmation));
    };

    return (
        <>
            <PageTitle title="Redefinir senha" icon={<KeyOutlined />} />
            <Form
                name="reset-password"
                className="reset-password-form"
                requiredMark={false}
                onFinish={onFinish}
                style={{ marginTop: 20 }}
            >
                <Form.Item
                    name="password"
                    rules={[
                        { min: 8, message: 'A senha possui no mínimo 8 caracteres' },
                        { required: true, message: 'A senha é obrigatória' }]}
                    hasFeedback
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Nova senha"
                    />
                </Form.Item>
                <Form.Item
                    name="passwordConfirmation"
                    dependencies={['password']}
                    rules={[
                        {
                            required: true,
                            message: 'Por favor, confirme a senha',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error('A senhas informadas não conferem!'));
                            },
                        }),
                    ]}
                    hasFeedback
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Confirmar nova senha"
                    />
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
                        Alterar senha
                    </Button>
                    <br />
                    {/* ou <span> <Link to={'/register'}>cadastre-se</Link></span> */}
                </Form.Item>
            </Form>
        </>
    )
}

export default ResetPasswordForm;