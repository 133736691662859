import styled from 'styled-components'

export const ToggleThemeSwith = styled.div<{ currentTheme: string | undefined }>`
    position: fixed;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    margin: 20px;
    box-shadow: 1px 1px rgba(0,0,0,0.2);
    background: ${props => props.currentTheme === 'light' ? '#303030' : '#FFFFFF'};
    color: ${props => props.currentTheme === 'light' ? '#FFFFFF' : '#303030'};
    z-index: 9999;
`