import thunk from 'redux-thunk';
import { createHashHistory } from 'history'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router'

import { API_URL } from '../config/config';
import createRootReducer from './combineReducers'

export const hashHistory = createHashHistory()
const middlewares = [
    routerMiddleware(hashHistory),
    thunk.withExtraArgument(API_URL)
];

export default function configureStore() {
    const store = createStore(
        createRootReducer(hashHistory),
        undefined,
        composeWithDevTools(
            applyMiddleware(...middlewares)
        ),
    );

    return store;
}

