import * as S from "./styles";
import { Card } from "antd";
import ResetPasswordForm from "./form";

const ResetPassword = () => {
    return (
        <S.ResetPasswordWrapper>
            <Card>
                <ResetPasswordForm />
            </Card>
        </S.ResetPasswordWrapper>
    );
};

export default ResetPassword;
