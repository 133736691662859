import moment from 'moment';
import { GetChargesByUser_charges as ChargeModel } from '../../../graphql/generated/GetChargesByUser';
import { MonthParse } from '../../../utils/models';

export interface ChartChargeModel extends ChargeModel {
    chargeAvegare: number
}

export const convertChargeDataset = (charges: ChargeModel[], filterDate?: string): Chart.ChartConfiguration | null => {
    if (charges === null) return null;
    if (!charges?.length) return null;

    const activeCharge: ChargeModel = charges.filter(x => x.active)[0] || null;
    if (!activeCharge) return null;

    const chargeChartDataset: Chart.ChartDataSets = {
        type: 'bar',
        label: 'Carga atual',
        data: [activeCharge.charge],
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        borderColor: 'rgba(54, 162, 235, 1)',
        yAxisID: "y-axis-0",
        fill: true,
    };

    const isConsolidatedByMonth = Math.round(moment.duration(moment().diff(moment(filterDate))).asMonths()) > 1 ? true : false;

    function extractGroupsByDate() {
        var groups: any = {};
        charges.forEach((val) => {
            var date = isConsolidatedByMonth ? new Date(val.updated_at).getMonth() + 1 : val.updated_at.split('T')[0];
            if (date in groups) {
                groups[date].push(val);
            } else {
                groups[date] = new Array(val);
            }
        });

        return groups;
    }

    const groups = extractGroupsByDate();
    function calculateAvarageOfGroup() {
        let groupsEntries: [string, ChargeModel | any][] = Object.entries(groups);

        groupsEntries.forEach((group: any) => {
            const groupData = group[1];
            groupData.chargeAverage = Math.round(groupData.reduce((a: number, b: ChargeModel) => a + b.charge, 0) / groupData.length);
            group[1] = groupData;
        });

        return groupsEntries;
    }

    const groupsEntriesData = calculateAvarageOfGroup();
    const groupsAnnotations: any[] = [];
    function convertGroupsAnnotations() {
        groupsEntriesData.forEach((groups) => {
            const labelData: string = isConsolidatedByMonth ? MonthParse[Number(groups[0])] : moment(groups[0] as string).format('DD/MM');

            groupsAnnotations.push({
                type: 'line',
                mode: 'horizontal',
                scaleID: "y-axis-0",
                borderWidth: 3,
                borderColor: '#001529',
                value: groups[1].chargeAverage,
                label: {
                    content: labelData,
                    enabled: true,
                    backgroundColor: '#001529'
                },
            })
        });
    }

    convertGroupsAnnotations();

    const chartConfiguration: Chart.ChartConfiguration = {
        type: 'bar',
        data: {
            labels: ['Charge'],
            datasets: [
                chargeChartDataset,
            ],
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            annotation: {
                annotations: [
                    ...groupsAnnotations
                ]
            },
            legend: {
                display: false,
                align: 'end'
            },
            tooltips: {
                enabled: true
            },
            scales: {
                yAxes: [
                    {
                        id: "y-axis-0",
                        display: true,
                        type: 'linear',
                        scaleLabel: {
                            display: true,
                            labelString: 'Charge'
                        },
                        ticks: {
                            min: 0,
                            max: 200,
                            beginAtZero: true
                        }
                    },
                ],
                xAxes: [
                    {
                        stacked: false
                    },
                    {
                        display: false,
                        type: 'linear',
                        scaleLabel: {
                            display: false,
                        },
                    }
                ]
            }
        }
    };

    console.log(chargeChartDataset);
    console.log(chartConfiguration);

    return chartConfiguration;
}