import { Button, Card, Col, Row, Space, Spin } from "antd";
import { useRef, useState } from "react";
import { CARD_TITLE } from "../../../../utils/styles";
import {
    PlusOutlined,
} from '@ant-design/icons';
import AddCompany from "../add-company";
import { CompanyList } from "../company-list";
import { useSelector } from "react-redux";
import { FlowScaleStore } from "../../../../redux/combineReducers";
import { NestedHierarchy } from "../../../../redux/hierarchy/state";
import { CompanyItem } from "../company-item";

const Hierachies = () => {
    const [showAddCompanyModal, setShowAddCompanyModal] = useState<boolean>(false);
    const [currentCompany, setCurrentCompany] = useState<NestedHierarchy | null>(null);
    const loading = useSelector((state: FlowScaleStore) => state.common.loading);
    const triggerChangeCompany = useRef<any>(null);

    const createHierarchy = () => {
        setShowAddCompanyModal(true);
    }

    return (
        <>
            <Spin spinning={loading}>
                <Card
                    title={'Hierarquias'}
                    headStyle={CARD_TITLE}
                    extra={
                        <Space>
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                loading={loading}
                                onClick={() => createHierarchy()}>
                                {'Criar'}
                            </Button>
                        </Space>}
                >
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col className="gutter-row" sm={24} lg={12} style={{ borderRight: '1px solid #eeeeee' }}>
                            {/* Lista de hierarquias */}
                            <CompanyList
                                triggerChangeCompany={triggerChangeCompany}
                                currentCompany={currentCompany}
                                onSelectCompany={(hierarchy: NestedHierarchy) => setCurrentCompany(hierarchy)}
                            />
                        </Col>
                        <Col className="gutter-row" sm={24} lg={12}>
                            {/* Gestão da hierarquia selecionada */}
                            <CompanyItem
                                company={currentCompany}
                                onChangeHierarchy={() => {
                                    triggerChangeCompany.current();
                                    setCurrentCompany(null);
                                }}
                            />
                        </Col>
                    </Row>
                </Card>
            </Spin>

            {/* Adicionar hierarquia */}
            <AddCompany
                showModal={showAddCompanyModal}
                closeModal={() => setShowAddCompanyModal(false)}
                onChangeCompany={() => triggerChangeCompany.current()}
            />
        </>

    );
}

export default Hierachies;