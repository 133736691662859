import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import CommonState from "./state";

export type CommonThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  CommonState,
  unknown,
  Action<string>
>

export interface AlertModel {
  showAlert?: boolean,
  message?: string,
  closable?: boolean,
  type?: 'success' | 'info' | 'warning' | 'error',
  timer?: number
}

export const initAlertModel: AlertModel = {
  showAlert: false
}