import httpClient from "../../utils/axios";
import { HierarchyThunk } from "./model";
import { AxiosResponse } from "axios";
import { changeCompaniesDomain, changeHierarchies, changeHierarchiesDomain } from "./action";
import { setLoading, toggleAlert } from "../common/action";
import { NestedHierarchy } from "./state";

/* Coleta dados da hierarquia dado um relatedId */
export const changeHierarchiesByRelatedId = (relatedId: number | null): HierarchyThunk => async (dispatch, state, api) => {
    if (!relatedId) return;
    dispatch(setLoading(true))
    httpClient.get(`${api}/hierarchies/nested/${relatedId}`)
        .then((result: AxiosResponse<Array<NestedHierarchy>>) => {
            const { data } = result;
            dispatch(changeHierarchies(data));
        })
        .catch((error: Response) => {
            console.log({ error });
            if (error?.status === 403)
                dispatch(toggleAlert({ showAlert: true, message: 'Não foi possível buscar dados da hierarquia. Usuário não autorizado.', timer: 5000, type: "error" }))
            else {
                dispatch(toggleAlert({ showAlert: true, message: 'Não foi possível buscar dados da hierarquia. Tente novamente mais tarde, se o erro persistir entre em contato com o suporte.', timer: 5000, type: "error" }))
            }
        }).finally(() => dispatch(setLoading(false)));
}


/* Coleta dados da hierarquia dado um relatedId */
export const getFlattedHierarchiesByName = (relatedId: number | null, name: string = ''): HierarchyThunk => async (dispatch, state, api) => {
    if (!relatedId) return;
    dispatch(setLoading(true))
    httpClient.get(`${api}/hierarchies/flatted/${relatedId}?name=${name}`)
        .then((result: AxiosResponse<Array<NestedHierarchy>>) => {
            const { data } = result;
            dispatch(changeHierarchiesDomain(data));
        })
        .catch((error: Response) => {
            console.log({ error });
            if (error?.status === 403)
                dispatch(toggleAlert({ showAlert: true, message: 'Não foi possível buscar dados sobre os setores. Usuário não autorizado.', timer: 5000, type: "error" }))
            else {
                dispatch(toggleAlert({ showAlert: true, message: 'Não foi possível buscar dados sobre os setores. Tente novamente mais tarde, se o erro persistir entre em contato com o suporte.', timer: 5000, type: "error" }))
            }
        }).finally(() => dispatch(setLoading(false)));
}

/* Coleta dados da hierarquia para usuários de onboarding */
export const getRootHierarchiesByName = (name: string = '', root: boolean = true): HierarchyThunk => async (dispatch, state, api) => {
    dispatch(setLoading(true))
    httpClient.get(`${api}/hierarchies?name_contains=${name}&root=${root}`)
        .then((result: AxiosResponse<Array<NestedHierarchy>>) => {
            const { data } = result;
            dispatch(changeCompaniesDomain(data));
        })
        .catch((error: Response) => {
            console.log({ error });
            if (error?.status === 403)
                dispatch(toggleAlert({ showAlert: true, message: 'Não foi possível buscar dados das empresas. Usuário não autorizado.', timer: 5000, type: "error" }))
            else {
                dispatch(toggleAlert({ showAlert: true, message: 'Não foi possível buscar dados das empresas. Tente novamente mais tarde, se o erro persistir entre em contato com o suporte.', timer: 5000, type: "error" }))
            }
        }).finally(() => dispatch(setLoading(false)));
}