import { Table } from 'antd';
import moment from 'moment';
import React from 'react';
import { GetActivities_activities_activity_histories as ActivityHistory } from '../../../../graphql/generated/GetActivities';

export interface ActivityHistoriesProps {
    activityHistories: ActivityHistory[]
}

const ActivityHistories = (props: ActivityHistoriesProps) => {
    const columns: any = [
        {
            title: 'Nome',
            dataIndex: 'lastname',
            key: 'lastname',
        },
        {
            title: 'Desafio',
            dataIndex: 'challenge',
            key: 'challenge',
        },
        {
            title: 'Competência',
            dataIndex: 'competence',
            key: 'competence',
        },
        {
            title: 'Data ínicio',
            dataIndex: 'created_at',
            key: 'created_at',
            fixed: 'right',
            width: 200,
            responsive: ['md'],
            render: (data: ActivityHistory[], item: ActivityHistory) => (
                <span>{moment(item.started_at).format('DD/MM/YYYY - HH:mm:ss')}</span>
            )
        },
        {
            title: 'Data fim',
            dataIndex: 'updated_at',
            key: 'updated_at',
            fixed: 'right',
            width: 200,
            responsive: ['md'],
            render: (data: ActivityHistory[], item: ActivityHistory) => (
                <span>{moment(item.finished_at).format('DD/MM/YYYY - HH:mm:ss')}</span>
            )
        }
    ];

    return (
        <Table
            columns={columns}
            title={() => 'Histórico da Atividade'}
            size={'small'}
            bordered
            dataSource={props.activityHistories ? props.activityHistories : []}
            style={{ borderBottom: '1px solid #eeeeee' }}
            locale={{
                emptyText: 'Nenhum histórico de atividade encontrado.'
            }}
            scroll={{ x: 300 }}
            sticky />
    )
}

export default ActivityHistories;