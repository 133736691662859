import { HierarchyTypes } from "./types";
import { NestedHierarchy } from "./state";

export function changeHierarchies(hierarchies: Array<NestedHierarchy>): HierarchyTypes {
    return {
        type: "CHANGE_HIERARCHIES",
        hierarchies: hierarchies
    }
}

export function changeHierarchiesDomain(hierarchies: Array<NestedHierarchy>): HierarchyTypes {
    return {
        type: "CHANGE_HIERARCHIES_DOMAIN",
        hierarchiesDomain: hierarchies
    }
}

export function changeCompaniesDomain(companies: Array<NestedHierarchy>): HierarchyTypes {
    return {
        type: "CHANGE_COMPANIES_DOMAIN",
        companiesDomain: companies
    }
}
