
import { useDispatch } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { RoleModel, RoleTypeModel, ROLE_PERMISSIONS } from '../../services/model/role';
import { GetUserData } from '../../services/user';

export const ProtectedRoute = (props: RouteProps) => {
    const isAuthenticated = localStorage.getItem('token') ?? null;
    const dispatch = useDispatch();
    const userData = GetUserData(dispatch);

    if (!isAuthenticated) return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
    const path = (props.path as string).split('/')[0];
    const getAllowedRoutesByRoleModel = (role: RoleTypeModel) => ROLE_PERMISSIONS.find(r => r.type === role)?.allowedRoutes;
    const canMoveFoward = (role: RoleTypeModel) => getAllowedRoutesByRoleModel(role)?.filter(r => r.match(RegExp(path as string, 'g')))?.length;
    const setRouteToRedirect = (role: RoleTypeModel, defaultPath = '/') => canMoveFoward(role)
        ? <Route {...props} />
        : <Redirect to={{ pathname: defaultPath, state: { from: props.location } }} />;

    switch ((userData?.role as RoleModel)?.type) {
        case RoleTypeModel.administrador:
            console.log('Permissão de administrador');
            return setRouteToRedirect(RoleTypeModel.administrador);

        case RoleTypeModel.analista:
            console.log('Permissão de analista');
            return setRouteToRedirect(RoleTypeModel.analista);

        case RoleTypeModel.lider:
            console.log('Permissão de lider');
            return setRouteToRedirect(RoleTypeModel.lider);

        case RoleTypeModel.onboarding:
            console.log('Permissão de onboarding');
            return setRouteToRedirect(RoleTypeModel.onboarding);

        default:
            return isAuthenticated
                ? <Route {...props} />
                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    }
}
