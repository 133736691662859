
import styled from "styled-components";

export const StepsContent = styled.div<{ currentTheme: string | undefined }>`
  padding: 15px 15px 0;
  margin-top: 20px;
  border: 1px solid ${props => props.currentTheme === 'light' ? '#e9e9e9' : 'rgba(0,0,0,0.2)' };
  border-radius: 5px;
`

export const StepsAction = styled.div`
    margin-top: 24px;
`
