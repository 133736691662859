import { useMutation } from "@apollo/client";
import { Button, Divider, message, Modal, Switch, Typography } from "antd"
import { useEffect, useState } from "react";
import { AutoCompleteManager } from "../../../common/autocompleteManager";
import { SelectPermissions } from "../../../common/selectPermissions";
import { updateUserInput } from "../../../graphql/generated/globalTypes";
import { MUTATION_UPDATE_USER } from "../../../graphql/mutations/mutations";
import { GET_USER_CREATED_FOR_COMPANY, GET_USER_TEAM_BY_MANAGER } from "../../../graphql/queries/queries";
import { RoleModel, UserModel } from "../../../services/model/role"
const { Paragraph } = Typography;
export interface UpdateUserModalProps {
    managerId: number | null,
    isOnboarding: boolean | undefined,
    show: boolean,
    userData: UserModel | null,
    closeModal: () => void
}

export const UpdateUserModal = (props: UpdateUserModalProps) => {
    const [currentManager, setCurrentManager] = useState<{ value: string, label: string } | null>(null);
    const [currentUserStatus, setCurrentUserStatus] = useState<boolean>(true);
    const [currentUserPermission, setCurrentUserPermission] = useState<number | null>(null);
    const [updateUser, { loading: mutationLoadingUpdateUser }] = useMutation(MUTATION_UPDATE_USER);

    // Modal
    const handleOk = () => {
        const userId = props.userData?.id.toString();

        if (!userId) return;

        let updateUserData: updateUserInput | null = {
            where: {
                id: userId
            }
        };

        if (currentUserStatus && !props.isOnboarding) {
            updateUserData = {
                ...updateUserData,
                data: {
                    blocked: !currentUserStatus,
                    manager: currentManager?.value,
                    role: currentUserPermission?.toString()
                }
            }

            updateUser({
                variables: {
                    input: updateUserData
                },
                refetchQueries: [
                    {
                        query: props.isOnboarding
                            ? GET_USER_CREATED_FOR_COMPANY
                            : GET_USER_TEAM_BY_MANAGER,
                        variables: {
                            manager: props.managerId
                        }
                    }
                ]
            }).then(() => {
                message.success('Dados do usuário atualizado com sucesso');
                props.closeModal();
            }).catch(() => {
                message.error('Não foi possível atualizar os dados do usuário.')
            });
        } else {
            updateUserData = {
                ...updateUserData,
                data: {
                    blocked: !currentUserStatus,
                }
            }

            updateUser({
                variables: {
                    input: updateUserData
                },
                refetchQueries: [
                    {
                        query: props.isOnboarding
                            ? GET_USER_CREATED_FOR_COMPANY
                            : GET_USER_TEAM_BY_MANAGER,
                        variables: {
                            manager: props.managerId
                        }
                    }
                ]
            }).then(() => {
                message.success('Usuário inativado com sucesso!');
                props.closeModal();
            }).catch(() => {
                message.error('Não foi possível inativar os dados do usuário.')
            });
        }
    }

    const handleCancel = () => {
        props.closeModal();
    }

    useEffect(() => {
        // Active
        const blocked = props.userData?.blocked;
        blocked !== null ? setCurrentUserStatus(!blocked) : setCurrentUserStatus(true);

        // Permission
        setCurrentUserPermission((props.userData?.role as RoleModel)?.id);

        // Manager
        setCurrentManager({
            value: (props.userData?.manager as UserModel)?.id.toString(),
            label: (props.userData?.manager as UserModel)?.username
        });
    }, [props.userData]);

    return (
        <Modal
            centered
            visible={props.show}
            title="Gerenciar usuário"
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancelar
                </Button>,
                <Button loading={mutationLoadingUpdateUser} key="submit" type="primary" onClick={handleOk}>
                    Atualizar
                </Button>,
            ]}
        >
            {/* Ativar ou inativar */}
            <Paragraph type="secondary">Ativar ou desativar usuário</Paragraph>
            <Switch
                style={{ padding: '0px 5px' }}
                checkedChildren="Ativo"
                unCheckedChildren="Inativo"
                checked={currentUserStatus}
                onChange={() => setCurrentUserStatus(!currentUserStatus)}
            />
            {/* Só exibe as opções para usuário ativo */}
            {currentUserStatus && !props.isOnboarding ?
                <>
                    {/* Modificar o gestor - Apenas para usuários que são criados por Administrador / Líder */}
                    <Divider />
                    <Paragraph type="secondary">Modificar a gestão</Paragraph>
                    <AutoCompleteManager
                        userData={props.userData}
                        currentManager={currentManager}
                        onSelect={(e) => setCurrentManager(e)}
                    />
                    <Divider />
                    {/* Modificar a permissão / cargo */}
                    <Paragraph type="secondary">Modificar a permissão</Paragraph>
                    <SelectPermissions
                        checkPromoted
                        currentPermission={currentUserPermission}
                        onSelect={(e) => setCurrentUserPermission(e)}
                    />
                </> : null}
        </Modal>
    );
}