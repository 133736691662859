import { Button, Card, Col, Row, Space, Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import { CARD_TITLE } from "../../../../utils/styles";
import {
    PlusOutlined,
} from '@ant-design/icons';
import AddHierarchy from "../add-hierarchy";
import { HierarchyList } from "../hierarchy-list";
import { useSelector } from "react-redux";
import { FlowScaleStore } from "../../../../redux/combineReducers";
import { NestedHierarchy } from "../../../../redux/hierarchy/state";
import { HierarchyItem } from "../hierarchy-item";

const Hierachies = () => {
    const [showAddHierarchyModal, setShowAddHierarchyModal] = useState<boolean>(false);
    const [currentParentHierarchy, setCurrentParentHierarchy] = useState<NestedHierarchy | null>(null);
    const [companyId, setCompanyId] = useState<number | null>(null);
    const loading = useSelector((state: FlowScaleStore) => state.common.loading);
    const userData = useSelector((state: FlowScaleStore) => state.auth.user);
    const triggerChangeHierarchy = useRef<any>(null);

    useEffect(() => {
        if (userData)
            setCompanyId(Number(userData.company?.id));

    }, [userData]);

    const createHierarchy = () => {
        setShowAddHierarchyModal(true);
    }

    return (
        <>
            <Spin spinning={loading}>
                <Card
                    title={'Hierarquias'}
                    headStyle={CARD_TITLE}
                    extra={
                        <Space>
                            {currentParentHierarchy ? <Button
                                style={{ marginRight: 5 }}
                                type="default"
                                onClick={() => setCurrentParentHierarchy(null)}>
                                Voltar ao início
                            </Button> : null}
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                loading={loading}
                                onClick={() => createHierarchy()}>
                                {currentParentHierarchy ? `Criar abaixo de ${currentParentHierarchy.name}` : 'Criar'}
                            </Button>
                        </Space>}
                >
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col className="gutter-row" sm={24} lg={12} style={{ borderRight: '1px solid #eeeeee' }}>
                            {/* Lista de hierarquias */}
                            <HierarchyList
                                companyId={companyId}
                                triggerChangeHierarchy={triggerChangeHierarchy}
                                currentHierarchy={currentParentHierarchy}
                                onSelectHiearchy={(hierarchy: NestedHierarchy) => setCurrentParentHierarchy(hierarchy)}
                            />
                        </Col>
                        <Col className="gutter-row" sm={24} lg={12}>
                            {/* Gestão da hierarquia selecionada */}
                            <HierarchyItem
                                hierarchy={currentParentHierarchy}
                                onChangeHierarchy={() => triggerChangeHierarchy.current(companyId)} />
                        </Col>
                    </Row>
                </Card>
            </Spin>

            {/* Adicionar hierarquia */}
            <AddHierarchy
                currentParentHierarchy={currentParentHierarchy}
                showModal={showAddHierarchyModal}
                closeModal={() => setShowAddHierarchyModal(false)}
                onChangeHierarchy={() => triggerChangeHierarchy.current(companyId)}
            />
        </>

    );
}

export default Hierachies;