import React, { useCallback, useEffect, useState } from 'react';
import { AutoComplete } from 'antd';
import { FlowScaleStore } from '../../redux/combineReducers';
import { useDispatch, useSelector } from 'react-redux';
import { getRootHierarchiesByName } from '../../redux/hierarchy/thunk';
import { HierarchyThunk } from '../../redux/hierarchy/model';

export interface AutoCompleteCompanyProps {
    onSelect: (option: { value: string, label: string }) => void
}

export const AutoCompleteCompany = (props: AutoCompleteCompanyProps) => {
    const dispatch = useDispatch();
    const dispatchCallback = useCallback((func: HierarchyThunk) => dispatch(func), [dispatch]);
    const [value, setValue] = useState('');
    const [options, setOptions] = useState<{ value: string, label: string }[]>([]);
    const companiesDomain = useSelector((state: FlowScaleStore) => state.hierarchy.companiesDomain);

    useEffect(() => {
        dispatchCallback(getRootHierarchiesByName());
    }, [dispatchCallback]);

    useEffect(() => {
        if (!companiesDomain.length)
            setOptions([]);
        else
            setOptions(companiesDomain.map((h) => ({ label: h.name, value: h.id.toString() })));

    }, [companiesDomain]);

    const onSearch = (searchText: string) => {
        dispatch(getRootHierarchiesByName(searchText));
    };

    const onSelect = (data: string, option: { value: string, label: string }) => {
        console.log('onSelect', data);
        console.log('onSelect', option);
        props.onSelect(option);
    };

    const onChange = (data: any, option: any) => {
        console.log({ option });
        setValue(option.label);
    };

    return (
        <>
            <AutoComplete
                value={value}
                options={options}
                onSelect={onSelect}
                onSearch={onSearch}
                onChange={onChange}
                placeholder={'Pesquise pela empresa'}
            />
        </>
    );
};

