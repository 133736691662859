import { Alert, Button, Card, Empty, Form, Input, message, Modal, Space, Tooltip, Typography } from "antd"
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useRef, useState } from "react";
import { NestedHierarchy } from "../../../../redux/hierarchy/state"
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { MUTATION_DELETE_HIERARCHY, MUTATION_UPDATE_HIERARCHY } from "../../../../graphql/mutations/mutations";
import { useMutation } from "@apollo/client";
import { deleteHierarchyInput, updateHierarchyInput } from "../../../../graphql/generated/globalTypes";
import { useSelector } from "react-redux";
import { FlowScaleStore } from "../../../../redux/combineReducers";

const { Text } = Typography

export interface CompanyItemProps {
    company: NestedHierarchy | null,
    onChangeHierarchy: () => void
}

export const CompanyItem = (props: CompanyItemProps) => {
    const [form] = Form.useForm();
    const formRef = useRef<any>(null);

    const [updateCompany, { loading: mutationUpdateCompanyLoading }] = useMutation(MUTATION_UPDATE_HIERARCHY);
    const [deleteCompany, { loading: mutationDeleteCompanyLoading }] = useMutation(MUTATION_DELETE_HIERARCHY);

    const userData = useSelector((state: FlowScaleStore) => state.auth.user);
    const [userSameCompany, setUserSameCompany] = useState<boolean>(false);

    useEffect(() => {
        if (props.company) {
            const { name, description, type } = props.company;
            form.setFieldsValue({
                name: name,
                description: description,
                type: type
            })
        } else {
            form.setFieldsValue({
                name: null,
                description: null,
                type: null
            })
        }
    }, [props.company, form]);

    useEffect(() => {
        setUserSameCompany(props.company?.id === userData?.company?.id);
    }, [props.company, userData])

    const confirmDelete = () => {
        Modal.confirm({
            title: `Excluindo a empresa: ${props.company?.name}`,
            icon: <ExclamationCircleOutlined />,
            content: 'Tem certeza que deseja excluir essa empresa? Se houver usuários atrelados, todos perderão o acesso ao sistema de forma definitiva (Só é recomendado o uso dessa opção em casos de erro de cadastro de novas empresas, sem usuários).',
            okText: 'Excluir',
            cancelText: 'Cancelar',
            onOk: removeCompany,
        });
    }

    const removeCompany = () => {
        const formData: deleteHierarchyInput = {
            where: {
                id: (props.company?.id as string).toString()
            }
        }

        deleteCompany({
            variables: {
                input: formData
            }
        })
            .then(() => {
                message.success('Empresa removida com sucesso');
                props.onChangeHierarchy();
            }).catch(() => {
                message.error('Não foi possível remover a empresa')
            });
    }

    const handleOk = () => {
        if (formRef && formRef.current) {
            formRef.current.submit();
        }
    };

    const confirmUpdateCompanyStatus = () => {
        Modal.confirm({
            title: `Desativando a empresa: ${props.company?.name}`,
            icon: <ExclamationCircleOutlined />,
            content: 'Tem certeza que deseja desativar essa empresa? Se houver usuários atrelados, todos perderão o acesso ao sistema. Você pode desfazer essa operação depois.',
            okText: 'Desativar',
            cancelText: 'Cancelar',
            onOk: updateCompanyStatus,
        });
    }

    const updateCompanyStatus = () => {
        const status = !props.company?.enabled;
        const formData: updateHierarchyInput = {
            data: {
                enabled: status
            },
            where: {
                id: (props.company?.id as string).toString()
            }
        }

        updateCompany({
            variables: {
                input: formData
            }
        })
            .then(() => {
                message.success(status ? 'Empresa ativada com sucesso' : 'Empresa desativada com sucesso');
                props.onChangeHierarchy();
            }).catch(() => {
                message.error('Não foi possível ativar ou desativar a empresa');
            });
    }

    const onFinish = (values: any) => {

        const formData: updateHierarchyInput = {
            data: {
                name: values.name,
                description: values.description,
                type: values.type
            },
            where: {
                id: (props.company?.id as string).toString()
            }
        }

        updateCompany({
            variables: {
                input: formData
            }
        })
            .then(() => {
                message.success('Empresa atualizada com sucesso');
                props.onChangeHierarchy();
            }).catch(() => {
                message.error('Não foi possível atualizar a empresa')
            });
    };

    return (
        <>
            {props.company
                ?
                <>
                    {/* Açoes */}
                    <Card
                        actions={[
                            <Space>
                                {/* Ativar ou desativar empresa */}
                                {props.company.enabled ?
                                    <Button disabled={userSameCompany} loading={mutationDeleteCompanyLoading} type="primary" danger key="submit" onClick={confirmUpdateCompanyStatus}>
                                        Desativar
                                    </Button>
                                    : <Button loading={mutationDeleteCompanyLoading} type="primary" key="submit" onClick={updateCompanyStatus}>
                                        Ativar empresa
                                    </Button>}
                                {/* Editar */}
                                <Button loading={mutationUpdateCompanyLoading} type="primary" key="submit" onClick={handleOk}>
                                    Editar
                                </Button>
                                {/* Remover empresa (em caso de erro de cadastro) */}
                                <Tooltip placement="right" title="Excluir">
                                    <Button disabled={userSameCompany} loading={mutationDeleteCompanyLoading} type="ghost" danger key="submit" onClick={confirmDelete}>
                                        <DeleteOutlined />
                                    </Button>
                                </Tooltip>
                            </Space>
                        ]}>
                        <Alert message="Edite abaixo as informações dessa empresa." type="info" style={{ marginBottom: 15, zIndex: 1 }} showIcon />
                        <Form
                            ref={formRef}
                            name="update-company"
                            layout={'vertical'}
                            form={form}
                            requiredMark={false}
                            onFinish={onFinish}
                        >
                            {/* Nome */}
                            <Text type="secondary" >
                                <strong>Nome atual:</strong> {props.company?.name}
                            </Text>
                            <Form.Item
                                style={{ marginTop: 5 }}
                                name="name"
                                rules={[
                                    { required: true, message: 'É obrigatório informar o nome' }
                                ]}
                            >
                                <Input
                                    value={props.company?.name || ''}
                                    onChange={(value) => console.log({ value })} />
                            </Form.Item>

                            {/* Descricao */}
                            <Text type="secondary">
                                <strong>Descrição atual:</strong> {props.company?.description}
                            </Text>
                            <Form.Item
                                style={{ marginTop: 5 }}
                                name="description"
                                rules={[
                                    { required: true, message: 'É obrigatório informar a descrição' }
                                ]}
                            >
                                <TextArea
                                    value={props.company?.description || ''}
                                    rows={4}
                                    autoSize
                                    onChange={(value) => console.log({ value })} />
                            </Form.Item>

                            {/* Tipo */}
                            <Text type="secondary">
                                <strong>Tipo atual:</strong> {props.company?.type}
                            </Text>
                            <Form.Item
                                style={{ marginTop: 5 }}
                                name="type"
                                rules={[
                                    { required: true, message: 'É obrigatório informar o tipo. Só é permitido letras minúsculas, sem espaço e caracteres especiais.', pattern: RegExp('^[a-z]+$', 'g') }
                                ]}
                            >
                                <Input
                                    value={props.company?.type || ''}
                                    onChange={(value) => console.log({ value })} />
                            </Form.Item>
                        </Form>
                        {/* Aviso de bloqueio da empresa do usuário */}
                        {userSameCompany && <Alert message="Não é possível desativar ou excluir a própria empresa. Apenas está disponível atualizar suas informações." type="warning" style={{ marginBottom: 15, zIndex: 1 }} showIcon />}
                    </Card>
                </>
                : <Empty style={{ padding: 50 }} image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Crie uma empresa nova selecionando o botão acima ou selecione uma empresa existente ao lado para fazer a gestão dela.'} />}
        </>
    )
}

