import React, { MutableRefObject, useCallback, useEffect, useState } from 'react'
import Chart from 'chart.js';
import * as S from '../styles'
import { Card, Col, DatePicker, Divider, Empty, Radio, Row } from 'antd';
import { ChartOptions, convertActivitiesChartDatasetsByDay, createdAtDate } from './service';
import { DocumentNode, useQuery } from '@apollo/client';
import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
import './index.css'
import ChargeChart from '../chargeChart';

export interface ActivitiesChartProps {
    query: DocumentNode,
    title?: string,
    randomKey?: number,
    userId?: number,
    variables?: object,
    triggerRefetch?: MutableRefObject<any>
}

const ActivitiesChart = (props: ActivitiesChartProps) => {
    const [selectedRadioValue, setSelectedRadioValue] = useState<ChartOptions>(ChartOptions.LastFifteenDays);
    const [selectedCustomDate, setSelectedCustomDate] = useState<any>(moment());
    const [chartConfiguration, setChartConfiguration] = useState<Chart.ChartConfiguration | null>(null);
    const { loading, data, refetch } = useQuery(props.query, {
        variables: { ...props.variables, createdAt: createdAtDate(selectedRadioValue, selectedCustomDate) },
    });

    useEffect(() => {
        if (data && data.activities) {
            setChartConfiguration(convertActivitiesChartDatasetsByDay(data.activities, createdAtDate(selectedRadioValue, selectedCustomDate)));
        }
    }, [data, selectedCustomDate, selectedRadioValue]);

    const setChartData = useCallback(() => {
        const ctx: any = document.getElementById(`actitivityChart-${props.randomKey}`);
        if (ctx && chartConfiguration) {
            ctx.parentNode.style.height = '400px';
            new Chart(ctx, chartConfiguration);
        }
    }, [chartConfiguration, props.randomKey]);

    useEffect(() => {
        if (chartConfiguration) {
            setChartData();
        }
    }, [chartConfiguration, setChartData]);

    const refetchData = useCallback((variables: object[]) => {
        refetch({ ...variables, createdAt: createdAtDate(selectedRadioValue, selectedCustomDate) });
    }, [refetch, selectedCustomDate, selectedRadioValue]);

    const triggerRefetch = useCallback(() => {
        if (props.triggerRefetch)
            props.triggerRefetch.current = refetchData
    }, [props.triggerRefetch, refetchData]);

    useEffect(() => {
        triggerRefetch();
    }, [triggerRefetch]);

    const options = [
        { label: 'Últimos 15 dias', value: ChartOptions.LastFifteenDays },
        { label: 'Últimos 30 dias', value: ChartOptions.LastMonth },
        { label: 'Selecionar data', value: ChartOptions.CustomDate },
    ];

    const onChange = (e: any) => {
        setSelectedRadioValue(e.target.value);
    }

    const onChangeDate = (e: any) => {
        setSelectedCustomDate(e);
    }

    return (
        <S.ChartWrapper>
            <Card title={props.title}>
                <Radio.Group
                    options={options}
                    onChange={onChange}
                    value={selectedRadioValue}
                    optionType="button"
                    buttonStyle="solid"
                />
                {selectedRadioValue === ChartOptions.CustomDate && <DatePicker
                    format={'DD/MM/YYYY'}
                    value={selectedCustomDate}
                    placeholder='Selecione'
                    allowClear={false}
                    locale={locale}
                    onChange={onChangeDate}
                />}
                <Divider />
                <Row align={'middle'} justify={'space-between'} >
                    <Col md={24} lg={20}>
                        <S.CardWrapper loading={loading}>
                            {data?.activities?.length
                                ? <canvas id={`actitivityChart-${props.randomKey}`} ></canvas>
                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Nenhum dado encontrado'} />}
                        </S.CardWrapper>
                    </Col>
                    <Col md={24} lg={4}>
                        <ChargeChart randomKey={props.randomKey} userId={props.userId} date={createdAtDate(selectedRadioValue, selectedCustomDate)} />
                    </Col>
                </Row>
            </Card>
        </S.ChartWrapper>
    )
}

export default ActivitiesChart;