import { Button, Form, Input, Modal } from "antd";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../../redux/auth/thunk";
import { FlowScaleStore } from "../../../redux/combineReducers";

export interface ForgotPasswordProps {
    defaultEmail?: string,
    showModal: boolean,
    closeModal: () => void
}

const ForgotPassword = (props: ForgotPasswordProps) => {
    const formRef = useRef<any>(null);
    const dispatch = useDispatch();
    const loading = useSelector((state: FlowScaleStore) => state.common.loading);

    const onFinish = (values: any) => {
        dispatch(forgotPassword(values.email))
    };

    const handleOk = () => {
        if (formRef && formRef.current) {
            formRef.current.submit();
        }

    };

    const handleCancel = () => {
        props.closeModal();
    };

    return (
        <>
            <Modal
                centered
                visible={props.showModal}
                title="Recuperação de senha"
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                        Enviar
                    </Button>,
                ]}
            >
                <Form
                    ref={formRef}
                    name="forgot-password"
                    layout={'vertical'}
                    requiredMark={false}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Informe seu e-mail:"
                        name="email"
                        initialValue={props.defaultEmail}
                        rules={[
                            {
                                type: 'email',
                                message: 'O e-mail não está no formato correto.',
                            },
                            { required: true, message: 'O e-mail é obrigatório' }
                        ]}
                    >
                        <Input disabled={props.defaultEmail ? true : false} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default ForgotPassword;