import { Button, Card, Col, Form, Input, Row } from "antd";
import { UserModel } from "../../../services/model/role";

interface ProfileFormProps {
    userData: UserModel | null;
    openResetPasswordModal: () => void
}

const ProfileForm = (props: ProfileFormProps) => {
    return (
        <Card
            style={{ marginTop: 16 }}
            actions={[
                <>
                    <Button type="link" onClick={() => props.openResetPasswordModal()}>Redefinir senha</Button>
                </>
            ]}>
            <Form
                name="profile"
                className="profile-form"
                layout={'vertical'}
                requiredMark={false}
                style={{ marginTop: 20 }}
            >
                <Row gutter={16}>
                    {/* Nome */}
                    <Col className="gutter-row" md={12} sm={24}>
                        <Form.Item
                            name="username"
                            label="Nome de usuário"
                            rules={[
                                { required: true, message: 'O nome é obrigatório' }
                            ]}
                            initialValue={props.userData?.username}
                            hasFeedback
                        >
                            <Input placeholder="Nome de usuário" disabled={true} />
                        </Form.Item>
                    </Col>
                    {/* Email */}
                    <Col className="gutter-row" md={12} sm={24}>
                        <Form.Item
                            name="email"
                            label="E-mail"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'O e-mail não está no formato correto.',
                                },
                                { required: true, message: 'O e-mail é obrigatório' }
                            ]}
                            initialValue={props.userData?.email}
                            hasFeedback
                        >
                            <Input placeholder="E-mail" disabled={true} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}

export default ProfileForm;