import { Action } from "redux"
import { ThunkAction } from "redux-thunk"
import AuthState from "../../redux/auth/state"
import { GetAllHierarchiesByRelatedId_hierarchies as Hierarchy } from "../../graphql/generated/GetAllHierarchiesByRelatedId";

export type AuthThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AuthState,
  unknown,
  Action<string>
>

export interface LoginFormModel {
  identifier: string,
  password: string
}

export interface UserModel {
  blocked: boolean,
  confirmed: boolean,
  email: string,
  id: number,
  updated_at: string,
  created_at: string | Date,
  username: string
  role: RoleModel | number,
  manager: UserModel | number,
  company: Hierarchy | null,
  hierarchy: Hierarchy | null,
}

export interface RoleModel {
  id: number,
  name: string,
  type: RoleTypeModel,
  description: string
}

export enum RoleTypeModel {
  "authenticated" = "authenticated",
  "public" = "public",
  "analista" = "analista",
  "lider" = "lider",
  "administrador" = "administrador",
  "onboarding" = "onboarding"
};

export interface RolePermissionModel {
  id?: string,
  type: RoleTypeModel,
  name: string,
  allowedRoutes: string[],
  badgeColor: string,
  canCreate?: RoleTypeModel[],
  canBePromotedTo?: RoleTypeModel[],
}

export const ROLE_PERMISSIONS: Array<RolePermissionModel> = [
  {
    type: RoleTypeModel.administrador,
    name: 'Administrador',
    allowedRoutes: ['/', '/activities', '/teams', '/profile', '/configuration'],
    badgeColor: 'volcano',
    canCreate: [RoleTypeModel.administrador, RoleTypeModel.lider, RoleTypeModel.analista],
    canBePromotedTo: []
  },
  {
    type: RoleTypeModel.analista,
    name: 'Analista',
    allowedRoutes: ['/', '/activities', '/teams', '/profile'],
    badgeColor: 'blue',
    canBePromotedTo: [RoleTypeModel.analista, RoleTypeModel.lider]
  },
  {
    type: RoleTypeModel.lider,
    name: 'Líder',
    allowedRoutes: ['/', '/activities', '/teams', '/profile'],
    badgeColor: 'purple',
    canCreate: [RoleTypeModel.lider, RoleTypeModel.analista],
    canBePromotedTo: [RoleTypeModel.analista, RoleTypeModel.lider]
  },
  {
    type: RoleTypeModel.onboarding,
    name: 'Onboarding',
    allowedRoutes: ['/', '/profile', '/configuration'],
    badgeColor: 'orange',
    canCreate: [RoleTypeModel.administrador],
    canBePromotedTo: []
  }
];

export const MANAGER_ROLE_PERMISSIONS = [RoleTypeModel.administrador, RoleTypeModel.onboarding, RoleTypeModel.lider];
