import { RoleModel, UserModel } from "../../services/model/role";
import { AuthTypes } from "./types";

export function authenticate(jwt: string, user: UserModel): AuthTypes {
    localStorage.setItem('token', jwt);

    return {
        type: "AUTHENTICATE",
        jwt: jwt,
        user: user
    }
}

export function setRoles(roles: Array<RoleModel>): AuthTypes {
    return {
        type: "SET_ROLES",
        roles: roles,
    }
}

export function logout(): AuthTypes {
    localStorage.removeItem('token');

    return {
        type: "AUTHENTICATE",
        jwt: '',
        user: null
    }
}