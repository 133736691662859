import { combineReducers } from "redux";
import { authReducer } from "./auth/reducer";
import { connectRouter } from 'connected-react-router'
import { commonReducer } from "./common/reducer";
import { hierarchyReducer } from "./hierarchy/reducer";
import AuthState from "./auth/state";
import CommonState from "./common/state";
import HierarchyState from "./hierarchy/state";

const createRootReducer = (history: any) => combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  common: commonReducer,
  hierarchy: hierarchyReducer
});

export interface FlowScaleStore {
  auth: AuthState,
  common: CommonState,
  hierarchy: HierarchyState
}

export default createRootReducer;