import { ConnectedRouter } from "connected-react-router"
import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { Login } from "../pages/login"
import { Register } from "../components/register"
import { ProtectedRoute } from "./protected"
import { hashHistory } from '../redux/store';
import { Activities } from "../pages/activities"
import { Teams } from "../pages/teams"
import ReadActivities from "../pages/activities/read-activities"
import ResetPassword from "../pages/login/reset-password"
import Profile from "../pages/profile"
import { Configuration } from "../pages/configuration"

export const routes = (
    <ConnectedRouter history={hashHistory}>
        <>
            <Switch>
                <Route path="/login" component={Login} />
                <Route path="/register" component={Register} />
                <Route exact={true} path="/reset-password" component={ResetPassword} />
                <ProtectedRoute exact={true} path="/" component={Activities} />
                <ProtectedRoute exact={true} path="/activities" component={Activities} />
                <ProtectedRoute exact={true} path="/teams" component={Teams} />
                <ProtectedRoute exact={true} path="/teams/:id" component={ReadActivities} />
                <ProtectedRoute exact={true} path="/profile" component={Profile} />
                <ProtectedRoute exact={true} path="/configuration" component={Configuration} />
                <Redirect from="*" to='/' />
            </Switch>
        </>
    </ConnectedRouter>
)

export const Routes = () => {
    return routes;
}